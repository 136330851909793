import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom'

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";
import FormControlLabel from '@mui/material/FormControlLabel';
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";
import Card from "@mui/material/Card";
import Checkbox from '@mui/material/Checkbox';

//pdf viewer
import { Worker } from '@react-pdf-viewer/core';
// Import the main component
import { Viewer, SpecialZoomLevel, ViewMode, ScrollMode } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
// Import styles
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

//script
import base64ToArrayBuffer from "common/script/base64ToArrayBuffer";

// Data
import UtilityService from "services/API/utility/UtilityService";
import MDTypography from "common/components/MD/MDTypography";
import { useManifestazione } from "common/context/GlobalContext";
import AuthService from "services/API/Auth/AuthService";





const RegolamentoTecnicoAccept = () => {


  const navigate = useNavigate();
    const { manifestazione } = useManifestazione();

  const [accettato, setAccettato] = useState(false);
  const [displayPDF, setDisplayPDF] = useState('stringa_iniziale');

  const defaultLayoutPluginInstance = defaultLayoutPlugin(

  );


  useEffect(() => {

            carica_pdf(manifestazione);
       
    
  }, []);

  const avanti = () => {

      navigate("/contratto");

  }

  const handleChangeE = (event) => {
    setAccettato(event.target.checked);

  }

  const annulla = () => {
    navigate("/contratto_vis");
  }


  const carica_pdf = () => {
    if (AuthService.isTokenOk()) {
    console.log('carica_pdf ');


      UtilityService.seleziona_file_man(manifestazione,"R_TECNICO")
        .then(response => {
          //  const filename =  response.headers.get('Content-Disposition').split('filename=')[1];
          var arrrayBuffer = base64ToArrayBuffer(response.data); //data is the base64 encoded string
          var blobb = new Blob([arrrayBuffer], { type: "application/pdf" });
          var url = URL.createObjectURL(blobb);
          var a = document.createElement('a');
          a.href = url;
          a.target = "_blank";
  
          a.download = "RegolamentoTecnicoDiQuartiere.pdf";
          ;
  
          document.body.appendChild(a); // append the element to the dom
          a.click();
          a.remove(); // afterwards, remove the element  
          setDisplayPDF(url);

        });
      }

  }



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3} px={3} spacing={6} >

        <Card>
          <HeaderForm titolo="Regolamento Tecnico di Quartiere Fieristico" exit={annulla} modo='' save={annulla} subject="" />
          <MDBox pt={4} pb={3} px={3} >
       
            <MDBox>
            <MDBox pt={4} pb={3} px={3} >
                   <MDTypography variant="body"  shrink={true} >Prima di visualizzare il Contratto, Si prega di scaricare, leggere e conservare il Regolamento Tecnico di Quartiere Fieristico 
                   </MDTypography>

        

              </MDBox>
              <MDBox >

                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"  >
                  <div style={{ height: "720px" }}>
                    {displayPDF !== 'stringa_iniziale' &&
                      <Viewer plugins={[defaultLayoutPluginInstance]} fileUrl={displayPDF} defaultScale={SpecialZoomLevel.PageFit}
                        ScrollMode={ScrollMode.Page} ViewMode={ViewMode.SinglePage}
                        initialPage={0} />}
                  </div>
                </Worker>


              </MDBox>
              <MDBox>
                <FormControlLabel control={<Checkbox onChange={handleChangeE} />} label="Accetto il Regolamento Tecnico di Quartiere Fieristico" />
              </MDBox>

              <MDBox sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>

                <MDBox sx={{ flex: '1 1 auto' }} />



                <MDButton variant="outlined" color="success" onClick={avanti} sx={{ justifyContent: 'flex-end' }}
                  disabled={!accettato}
                >Avanti </MDButton>


              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}


export default RegolamentoTecnicoAccept;
