import DashboardLayout from "layouts/DashboardLayout";
import Footer from "layouts/components/Footer";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

//validazione lato client
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

// Data
import { Alert, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, MenuItem } from "@mui/material";
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";
import MDInput from "common/components/MD/MDInput";
import MDTypography from "common/components/MD/MDTypography";
import ReactHookFormCheckBox from "common/components/mycomponents/ReactHookFormCheckBox";
import ReactHookFormSelect from "common/components/mycomponents/ReactHookFormSelect";
import HeaderForm from "layouts/components/HeaderForm";
import Messaggio from "models/Messaggio";
import ComunicazioniService from "services/API/Comunicazioni/ComunicazioniService";

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import AllegatiList from "common/components/implemented/Lists/AllegatiList";
import ProfilesList from "common/components/implemented/Lists/ProfilesList";
import AllegatoEditModal from "pages/riservata/utilita/documenti/AllegatoEditModal";
import ElencoDocumentiModal from "pages/riservata/utilita/documenti/ElencoDocumentiModal";
import ElencoProposteModal from "../expo_richieste_admin/ElencoProposteModal";
import ExpoRichiesteService from "services/API/ExpoRichieste/ExpoRichiesteService";
import AuthService from "services/API/Auth/AuthService";


function MessaggioEdit() {

    const location = useLocation();
    const navigate = useNavigate();

    const { modo, soggetto, righe, id_proposta, chiamante,id_modello } = location.state;


    const [messaggio, setMessaggio] = useState(Messaggio);
    const [readOnly, setReadOnly] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const [lista_allegati, setLista_allegati] = useState([{ idAllegato: 0, nome_allegato: '',descrizione:'',nome_file:'' }]);
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);

    const [destinatari, setDestinatari] = useState([]);
    const [destinatariList, setDestinatariList] = useState([]);
    const [proposte, setProposte] = useState([]);
    const [partecipazioni, setPartecipazioni] = useState([]);
    const [modelli, setModelli] = useState([]);
   const [deleteBtn, setDeleteBtn] = useState('');
   
    useEffect(() => {
        if (righe != null) {
            console.log("righe non nullo:");
            console.log(righe);
         
            let lista = [];
            righe.map(er => {
                console.log(er.idRichiesta);
                console.log(er.id_richiesta0);
                setDestinatari([...destinatari, er.id_utente]);
                setProposte([...proposte, er.idRichiesta!=undefined?er.idRichiesta:er.id_richiesta_0]);
                setPartecipazioni([...partecipazioni, er.idPartecipazione]);            
                lista = ([...lista, {
                    image: "", ragione_sociale: er.ragione_sociale, id_utente: er.id_utente, id_proposta:  er.idRichiesta!=undefined?er.idRichiesta:er.id_richiesta_0,
                    protocollo: er.protocollo, action: null,id_partecipazione_appoggio:er.idPartecipazione
                }]);
            })
            setDestinatariList(lista);
        } else {
            console.log("righe nullo");
        }
    }, [righe]);
  

useEffect(() => {
      
        console.log("carico i modelli");

        async function fetchInitialData2() {
                let response={};
                if (chiamante == "expo_richieste_na_admin" || chiamante=="expo_richieste_ok_admin") {
                    response=await ComunicazioniService.seleziona_modello_da_nome("Accettazione-Non Accettazione");
                }
                else{
                    if (chiamante == "expo_partecipazioni_admin" ) {
                        if(id_modello==3)
                        response=await ComunicazioniService.seleziona_modello_da_nome("Spazio espositivo");
                        if(id_modello==4)
                            response=await ComunicazioniService.seleziona_modello_da_nome("Dati Catalogo");
                    }
                    else{
                    response = await ComunicazioniService.getAllModelli();}
                    }
                
                if(response.errors)
                    {console.log(response.errors);}
                else{

                   
                    if (chiamante == "expo_richieste_na_admin" || chiamante=="expo_richieste_ok_admin" || chiamante=="expo_partecipazioni_admin") {
                        
                        retriveModello(response.data[0]) ;  
                    }else{

                        setModelli([...response.data]);
                    }
                }
        }
        fetchInitialData2();

    }, [chiamante]);





    async function importa() {

          if (AuthService.isTokenOk()) {
        const id_modello = getValues("modello");

        const mscelto = modelli.find((m) => m.idModello == id_modello);

        const response = await ComunicazioniService.seleziona_dettagli_modello(id_modello);
        //  console.log(response.data);
        response.errors ? console.log(response.errors) : retriveModello(response.data);}
    }

    function retriveModello(data) {
        console.log("RETRIVE =" + data.idModello);

        let nextShapes ={};

        if (chiamante == "expo_richieste_na_admin" || chiamante=="expo_richieste_ok_admin") {
            nextShapes =
            {
                ...messaggio,
                oggetto: data.oggetto,
                testo: data.testo,
                allegati:[{idAllegato:righe[0].id_allegato_esito,nome_allegato:righe[0].nome_allegato_esito, descrizione:righe[0].descrizione_esito,nome_file:righe[0].nome_file_esito}],
                testo_email_notifica: data.testo_email_notifica,
                destinatario_email_notifica: data.destinatario_email_notifica,
                accettazione:  chiamante!="expo_partecipazioni_admin"
            };
          
           
                
        }else{


        nextShapes =

        {
            ...messaggio,
            oggetto: data.oggetto,
            testo: data.testo,
            allegati: data.allegati,
            testo_email_notifica: data.testo_email_notifica,
            destinatario_email_notifica: data.destinatario_email_notifica,
            accettazione: data.accettazione
        };
        
    }
        // Re-render with the new array
        setMessaggio(nextShapes);
        
        if (chiamante == "expo_richieste_na_admin"|| chiamante=="expo_richieste_ok_admin") {

          //  const esito=ExpoRichiesteService.seleziona_esito(righe[0].id_allegato_esito)
            const allegato={

                idAllegato:righe[0].id_allegato_esito,
                nome_allegato:righe[0].nome_allegato_esito,
                descrizione:righe[0].nome_allegato_esito
            }
       
       
          setLista_allegati((prevLista) => {
                const nuovaLista = [...prevLista,allegato];
                console.log("Lista aggiornata:", nuovaLista);
                return nuovaLista;
              });
        }else{
            setLista_allegati(data.allegati);

        }
        //setValue("oggetto",mscelto.oggetto);
        //   setValue("testo",mscelto.testo);
        /*  setValue("nome_allegato1", mscelto.nome_allegato1);
          
          setValue("nome_allegato2", mscelto.nome_allegato2);
          setValue("nome_allegato3", mscelto.nome_allegato3);
          setValue("testo_email_notifica", mscelto.testo_email_notifica);
          setValue("destinatario_email_notifica", mscelto.destinatario_email_notifica);
          setValue("accettazione", mscelto.accettazione);*/


    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Funzione per aggiungere un nuovo oggetto alla lista
    const handleAddAllegato = (newObject) => {
        setLista_allegati((prevObjects) => [...prevObjects, newObject]);
        handleClose(); // Chiudi la modale dopo il salvataggio
        handleClose2(); // Chiudi la modale dopo il salvataggio
    };

    const handleRemoveAllegato = (idAllegato) => {

        var filtrato = lista_allegati.filter(function (valore, indice, vett) {
            return valore.idAllegato != idAllegato;
        });
        setLista_allegati(filtrato);
        handleClose(); // Chiudi la modale dopo il salvataggio
    };

    const aggiungi_allegato = () => {
        handleShow();
    }

    const scegli_allegato = () => {
        handleShow2();
    }

    const aggiungi_destinatario = () => {
        handleShow3();
    }


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    // Funzione per aggiungere un nuovo oggetto alla lista
    const handleAddDestinatario = (newObject) => {

        console.log(newObject.azienda);

        setDestinatariList((prevObjects) => [...prevObjects, newObject]);
        handleClose3(); // Chiudi la modale dopo il salvataggio

    };

    const handleRemoveDestinatario = (id_utente) => {

        var filtrato = destinatariList.filter(function (valore, indice, vett) {
            return valore.id_utente != id_utente;
        });
        setDestinatariList(filtrato);
        handleClose3(); // Chiudi la modale dopo il salvataggio
    };



    //////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);




    const validationSchema = Yup.object().shape({
        /*   nome_manifestazione: Yup.string().required('Nome richiesto').max(100, 'Nome non deve superare 100 caratteri'),
           
           pdf_regolamento: Yup.mixed().when("$isPresent", {
               is: (val) => {
                   return modo === 'new';
               },
               then: (s) => s.required('VALIDATION_FIELD_REQUIRED')
                               .test("fileSize", "The file is too large", (value) => {
                                   return value && value[0].size <= 2000000;
                               })
                               .test("type", "Only the following formats are accepted: .pdf ", (value) => {
                                   return value && (
                                       value[0].type === 'application/pdf'
                                   );
                               }),
               otherwise: (s) => s,
           }),
         
       /*  
           pdf_regolamento: Yup.mixed()
               .required('VALIDATION_FIELD_REQUIRED')
               .test("fileSize", "The file is too large", (value) => {
                   return value && value[0].size <= 2000000;
               })
               .test("type", "Only the following formats are accepted: .pdf ", (value) => {
                   return value && (
                       value[0].type === 'application/pdf'
                   );
               }),*/


        /*   pdf_non_accettazione: Yup.mixed().nullable()
               .test("fileSize", "The file is too large", (value) => {
                  return value==null || value[0].size <= 2000000;
               })
               .test("type", "Only the following formats are accepted: .pdf ", (value) => {
                   return (
                       value==null || value[0].type === 'application/pdf'      );
               }),*/
    });


    useEffect(() => {
          if (AuthService.isTokenOk()) {
        if (soggetto != null) {
            console.log("soggetto non nullo: id=" + soggetto.idMessaggio);
            retrive_soggetto();
        } else {
            console.log("soggetto nullo");
        }}
    }, [soggetto]);

    async function retrive_soggetto() {
        console.log("Cambiata msg->seleziono i dettagli :" + soggetto.idMessaggio);
        if (soggetto.idMessaggio != null) {
            const response = await ComunicazioniService.seleziona_dettagli_msg(soggetto.idMessaggio);
            //  console.log(response.data);
            response.errors ? console.log(response.errors) : retrive(response.data);


        }
    }

    function retrive(data) {
        console.log("RETRIVE =" + data.idMessaggio);

        setMessaggio({
            idMessaggio: data.idMessaggio,
            id_utente: data.id_utente,
            azienda: data.azienda,
            id_proposta: data.id_proposta,
            protocollo_proposta: data.protocollo_proposta,
            oggetto: data.oggetto,
            testo: data.testo,
            testo_email_notifica: data.testo_email_notifica,
            destinatario_email_notifica: data.destinatario_email_notifica,
            allegati: data.allegati,
            inviato: data.inviato, accettazione: data.accettazione,
            string_data_ins: data.string_data_ins,
            string_data_invio: data.string_data_invio,

        })

        console.log("selezionata  " + data.idMessaggio);

    }


    // effect runs when user state is updated
    useEffect(() => {

        if (modo != 'new') {
            // reset form with user data
            //console.log( messaggio);
            //setValue("destinatario", messaggio.destinatario);
            setDestinatari([]);
            setProposte([]);
         
            let lista = [];

            setDestinatari([...destinatari, messaggio.id_utente]);
            setProposte([...proposte, messaggio.id_proposta,]);
            lista = ([...lista, {
                image: "", name: messaggio.azienda, id_utente: messaggio.id_utente, id_proposta: messaggio.id_proposta,
                description: "Proposta protocollo " + messaggio.protocollo_proposta, action: null,
            }]);

            setDestinatariList(lista);

        }

        setValue("oggetto", messaggio.oggetto);
        setValue("testo", messaggio.testo);
        setLista_allegati(messaggio.allegati);

        setValue("testo_email_notifica", messaggio.testo_email_notifica);
        setValue("destinatario_email_notifica", messaggio.destinatario_email_notifica);
        setValue("accettazione", messaggio.accettazione);
        if (messaggio.inviato || modo=='vis'){
            setReadOnly(true);
            setDisabled(true);
            setDeleteBtn("");
        }else{
            setDeleteBtn({handleOpen})
        }
    }, [messaggio]);




    const {
        register, //register inputs
        getValues, reset, setValue, control,
        handleSubmit,//handle form submit
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
    });


    //sono quelli lato server
    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const insertMsg = (formData) => {
        setError(null);
  if (AuthService.isTokenOk()) {
        //mi serve solo l'id dell'allegato
     (lista_allegati!=null && lista_allegati.length>0) &&   lista_allegati.map(a => a.file_allegato = null);
     
     
     
        var datas = [];
        destinatariList.map(
            (dst) => {
                var data = {
                    idMessaggio: messaggio.idMessaggio,
                    id_modello_appoggio:id_modello,
                    id_utente: dst.id_utente,
                    id_proposta: dst.id_proposta,
                    id_partecipazione_appoggio:dst.id_partecipazione_appoggio,
                    oggetto: formData.oggetto,
                    testo: formData.testo,
                    allegati: lista_allegati,
                    testo_email_notifica: formData.testo_email_notifica,
                    destinatario_email_notifica: formData.destinatario_email_notifica,
                    accettazione: formData.accettazione


                };


                datas.push(data);
            }

        )




        if (modo === 'new') {
            console.log("Inserisci ");
            ComunicazioniService.createMsg(datas)
                .then(response => {
                    handleResponse(response, 'INS');
                })
                .catch(errore => {
                    handleError(errore);
                });
        } else {



            // console.log("update messaggio " + datas[0].idMessaggio);
            ComunicazioniService.updateMsg(messaggio.idMessaggio, datas[0])
                .then(response => {
                    handleResponse(response, 'MOD');
                })
                .catch(errore => {
                    handleError(errore);
                });

        }}
    };



    function handleCloseConfirm() {

        setOpen(false);
    }
    function handleConfirm() {
        setConfirm(true);
        setOpen(false);
         deleteMsg();
    }
    const handleOpen = () => {
        console.log("OPEN DIALOG");
        setOpen(true);
    }

     const deleteMsg= () => {
         setError(null);
          if (AuthService.isTokenOk()) {
         if (modo === 'edit') {
 
             console.log("delete message");
             ComunicazioniService.removeMsg(messaggio.idMessaggio)
                 .then(response => {
                     console.log("handleResponse");
                     handleResponse(response, 'DEL');
                 })
                 .catch(errore => {
                     console.log("handleError");
                     handleError(errore);
                 });
 
 
         }}
     };


    function handleResponse(response, op) {
        console.log("RESPONSE: id_proposta=" + id_proposta);


        navigate("/" + chiamante, { state: { submitted: true, salvato: op, id_proposta: id_proposta } });
    }

    function handleError(error) {
        setError(error);
        console.log("ERRORE" + error);
        console.log(error.code);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log("ERROR:RESPONSE" + error.response.data);
            setErrorMessage(error.response.data);

        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log("ERROR:REQUEST" + error.request);
            setErrorMessage(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            setErrorMessage(error.message);
        }

    }

    const annulla = () => {
        console.log("ANNULLA");


        navigate("/" + chiamante, { state: { id_proposta: id_proposta } });

    }





    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HeaderForm titolo="Messaggio" exit={annulla} modo={modo}  
         remove={!disabled && handleOpen}  />


            <MDBox pt={6} pb={3}>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                    <DialogTitle id="alert-dialog-title">
                        Eliminare questo record?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Sei sicuro di voler eliminare questo record?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MDButton onClick={handleClose}>No</MDButton>
                        <MDButton onClick={handleConfirm} autoFocus>
                            SI
                        </MDButton>
                    </DialogActions>
                </Dialog>


                <form >
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>


                                {error !== null &&
                                    <Alert color="error"><ErrorOutlineOutlinedIcon fontSize="small" />&nbsp;{errorMessage}</Alert>
                                }
                                <MDBox pt={4} pb={3} px={3}>
                                    <MDBox component="form" role="form">
                                        <Grid container spacing={3}>

                                            <Grid item xs={12} sm={12} mb={2}>
                                                <MDBox>
                                                    <ProfilesList
                                                        title="Destinatari"
                                                        profiles={destinatariList}
                                                        onDel={handleRemoveDestinatario}
                                                    />
                                                    <MDBox p={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '3fr 1fr 1fr ' }}>
                                                        <MDBox></MDBox>
                                                      {   (chiamante != "expo_richieste_na_admin" && chiamante!="expo_richieste_ok_admin") &&
                                                        <MDButton variant="gradient" color="dark" onClick={aggiungi_destinatario} disabled={disabled} >
                                                            Aggiungi Nuovo
                                                        </MDButton>
}
                                                    </MDBox></MDBox>
                                            </Grid>

                                            {(readOnly != "readOnly" && 
                                            (chiamante != "expo_richieste_na_admin") && chiamante!="expo_richieste_ok_admin" && chiamante!="expo_partecipazioni_admin"
                                            ) && <Grid item xs={12} sm={12} mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr 1fr' }}>
                                                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                                                    Importa da Modello
                                                </MDTypography>
                                                <MDBox>
                                                    <ReactHookFormSelect
                                                        id="modello"
                                                        name="modello"
                                                        label="Modello di Comunicazione"
                                                        defaultValue=''
                                                        variant="outlined"
                                                        control={control}
                                                        required
                                                    >
                                                        {modelli.map((cm) => (
                                                            <MenuItem
                                                                key={cm.idModello}
                                                                value={cm.idModello}
                                                            >
                                                                {cm.nome_modello}
                                                            </MenuItem>
                                                        ))}

                                                    </ReactHookFormSelect>
                                                </MDBox>
                                                <MDBox ml={2}>
                                                    <MDButton variant="gradient" color="success" onClick={importa}   >
                                                        Importa
                                                    </MDButton>
                                                </MDBox>


                                            </Grid>}
                                            <Grid item xs={12} sm={12} mb={2}>
                                                <Card >
                                                  
                                                <MDTypography display="inline" variant="h5" textTransform="capitalize" fontWeight="bold">Messaggio Farfiera</MDTypography>
                                         
                                                    <CardContent>
                                            <Grid item xs={12} sm={12} mb={2}>

                                                <MDInput type="text" label="Oggetto " fullWidth disabled={disabled}

                                                    name="oggetto"
                                                    {...register('oggetto')} autocomplete="off"

                                                    error={errors.oggetto ? true : false} InputLabelProps={{ shrink: true }} />
                                                {
                                                    errors.oggetto ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.oggetto?.message}</span> : ''
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={12} mb={2}>

                                                <MDInput type="text" label="Testo della comunicazione" fullWidth required disabled={disabled}
                                                    name='testo' {...register('testo')} multiline rows={3} InputLabelProps={{ shrink: true }}
                                                    error={errors.testo ? true : false} />
                                                {errors.testo ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.testo?.message}</span> : ''}
                                            </Grid>
                                            <Grid item xs={12} sm={12} mb={2}>
                                                <MDBox>

                                                    <AllegatiList title="Allegati" allegati={lista_allegati} onDel={handleRemoveAllegato}></AllegatiList>
                                                    <MDBox p={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '3fr 1fr 1fr ' }}>
                                                        <MDBox></MDBox>
                                                        <MDButton variant="gradient" color="dark" onClick={aggiungi_allegato} disabled={disabled} >
                                                            Aggiungi Nuovo
                                                        </MDButton>
                                                        <MDButton variant="gradient" color="dark" onClick={scegli_allegato} disabled={disabled}>
                                                            Scegli
                                                        </MDButton>
                                                    </MDBox>
                                                </MDBox>
                                            </Grid>
                                            </CardContent>

                                            </Card>

</Grid>
<Grid item xs={12} sm={12} mb={2}>
<Card >
                                                  
                                                  <MDTypography display="inline" variant="h5" textTransform="capitalize" fontWeight="bold" disabled={disabled}>Email di notifica</MDTypography>
                                           
                                                      <CardContent>

                                            <Grid item xs={12} sm={12} mb={2}>

                                                <MDInput type="text" label="Testo email di notifica" fullWidth required disabled={disabled}
                                                    name='testo_email_notifica' {...register('testo_email_notifica')} multiline rows={3} InputLabelProps={{ shrink: true }}
                                                    error={errors.testo_email_notifica ? true : false} />
                                                {errors.testo_email_notifica ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.testo_email_notifica?.message}</span> : ''}
                                            </Grid>

                                            <Grid item xs={12} sm={12} mb={2}>
                                                <ReactHookFormSelect
                                                    id="destinatario_email_notifica"
                                                    name="destinatario_email_notifica"
                                                    label="Destinatario Email di Notifica"
                                                    control={control}
                                                    defaultValue=''
                                                    variant="outlined"
                                                    disabled={disabled}
                                                >

                                                    <MenuItem key='account' value="EMAIL_ACCOUNT">
                                                        EMAIL ACCOUNT
                                                    </MenuItem>
                                                    <MenuItem key='responsabile' value="EMAIL_RESPONSABILE">
                                                        EMAIL RESPONSABILE
                                                    </MenuItem>
                                                    <MenuItem key='azienda' value="EMAIL_AZIENDA">
                                                        EMAIL AZIENDALE
                                                    </MenuItem>

                                                </ReactHookFormSelect>
                                            </Grid>
                                            </CardContent>

</Card>
</Grid>

                                            <Grid item xs={12} sm={6} mb={2}>
                                                <ReactHookFormCheckBox
                                                    label={<MDBox>
                                                        <MDTypography
                                                            variant="button"
                                                            fontWeight="regular"
                                                            color="text"
                                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                                        >
                                                            &nbsp;&nbsp;E' la comunicazione di accettazione/non accettazione della proposta&nbsp;
                                                        </MDTypography>

                                                    </MDBox>}
                                                    name="accettazione"
                                                    control={control} 
                                                    disabled={disabled}/>
                                            </Grid>
                                            <Grid item xs={12} sm={6} mb={2}>

                                            </Grid>
                                            <Grid item xs={12} sm={6} mb={2}>

                                            </Grid>
                                        </Grid>
                                        <MDBox mb={2}>
                                        </MDBox>
                                        <MDBox sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} mt={4} mb={1}>

                                            <MDBox sx={{ flex: '1 1 auto' }} />
                                            <MDBox ml={2}>
                                                <MDButton variant="outlined" color="success" onClick={annulla} type="submit"  >
                                                    Annulla
                                                </MDButton>
                                            </MDBox>
                                           
                                            <MDBox ml={2}>
                                                <MDButton variant="gradient" color="success" onClick={handleSubmit(insertMsg)} type="submit" disabled={disabled}  >
                                                    Salva
                                                </MDButton>
                                            </MDBox>

                                        </MDBox>

                                    </MDBox>
                                </MDBox>




                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </MDBox>

            <AllegatoEditModal
                onSave={handleAddAllegato}
                onClose={handleClose}
                show={show}
            />
            <ElencoDocumentiModal
                onSave={handleAddAllegato}
                onClose={handleClose2}
                show={show2}
            />
            <ElencoProposteModal
                onSave={handleAddDestinatario}
                onClose={handleClose3}
                show={show3}
            />


            <Footer />
        </DashboardLayout>
    );
}

export default MessaggioEdit;