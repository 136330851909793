/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

import AuthService from 'services/API/Auth/AuthService.js';

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import Icon from '@mui/material/Icon';
import MuiLink from "@mui/material/Link";


// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDTypography from "common/components/MD/MDTypography";
import MDInput from "common/components/MD/MDInput";
import MDButton from "common/components/MD/MDButton";
import Alert from '@mui/material/Alert';

// Authentication layout components
import BasicLayout from "layouts/BasicLayout";

// Images
import bgImage from "common/assets/images/bg-sign-in-basic.png";
import logo from "common/assets/images/logos/logo_icon.jpg";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { useManifestazione } from "common/context/GlobalContext";
import UtilityService from "services/API/utility/UtilityService";

function Basic() {

  const [userid, setUserid] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);


  let navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const remember = localStorage.getItem("rememberMe") === "true";
    setRememberMe(remember);
  }, []);




  const handleSetRememberMe = () => {
    localStorage.setItem("rememberMe", !rememberMe);
    setRememberMe(!rememberMe);
  }

  const { manifestazione, lista_man, setLista_man, setManifestazione } = useManifestazione();




  async function handleClickLogin(e) {

    if (!checkAuthentication()) {
      console.log("chiamato login con userid =" + userid + " e password= " + password);
      await AuthService.login(userid, password)
        .then(response => handleResponse(response))
        .catch(error => handleError(error));
    }
    else { navigate(`/welcome`); }
  }

  function handleError(error) {
    setError(error.response.data);
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);

    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    // console.log(error.config);

  }

  const checkAuthentication = () => {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");

    if (token) {
      console.log("Utente autenticato con token:", token);

      if (AuthService.isTokenOk())
        return true;
      else return false;
    } else {
      console.log("Utente non autenticato.");
      return false;
    }
  };



  function handleResponse(response) {

    // Salva il token in base all'opzione "Ricordami"
    const user = JSON.parse(localStorage.getItem('user'));
    const fakeToken = user.accessToken

    if (rememberMe) {
      localStorage.setItem("token", fakeToken); // Persistente
    } else {
      sessionStorage.setItem("token", fakeToken); // Temporaneo
    }

    console.log("Vado a welcome");
    navigate(`/welcome`);
  }


  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="success"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >

          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            FARFIERA
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>


          </Grid>
        </MDBox>
        <MDBox pt={1} pb={3} px={3}>
          <MDBox opacity={1} textAlign="center">
            <img src={logo} alt="Logo" width="180" />
          </MDBox>

          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="text" color='success' label="Username o Email" fullWidth name='userid' value={userid} onChange={(e) => { setUserid(e.target.value) }} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" fullWidth name='password' value={password} onChange={(e) => { setPassword(e.target.value) }} />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Ricordami!
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="success" fullWidth onClick={handleClickLogin}>
                Log in!
              </MDButton>
            </MDBox>
            {error !== null &&
              <Alert color="error" dismissible><ErrorOutlineOutlinedIcon fontSize="small" />&nbsp;{error.message}</Alert>
            }




            <MDBox mt={3} mb={1} textAlign="center">

              <MDTypography variant="button" color="text">
                Sei un'<b>Azienda</b> e vuoi partecipare?{" "}
                <Link to={`/expo_register`}>
                  <MDTypography

                    variant="button"
                    color="success"
                    fontWeight="medium"
                    textGradient
                  >
                    Registrati
                  </MDTypography></Link>
              </MDTypography>
            </MDBox>



            <MDBox mt={1} mb={1} textAlign="center">

              <MDTypography variant="button" color="text">
                Non ricordi la password?{" "}
                <Link to={`/request_change_pwd`}>
                  <MDTypography
                    variant="button"
                    color="success"
                    fontWeight="medium"
                    textGradient
                  >
                    Cambia Password
                  </MDTypography></Link>
              </MDTypography>
            </MDBox>
            <MDBox mt={3} mb={0} textAlign="center">

              <MDTypography variant="button" color="text">
                Sei un <b>Visitatore</b>?{" "}
                <Link to={`/register`}>
                  <MDTypography

                    variant="button"
                    color="success"
                    fontWeight="medium"
                    textGradient
                  >
                    Registrati
                  </MDTypography></Link>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
