import { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";

import MDTypography from "common/components/MD/MDTypography";


// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";

//prop-types is a library for typechecking of props
// Data
import { useManifestazione } from "common/context/GlobalContext";
import DefaultInfoCard from "common/components/implemented/Cards/InfoCards/DefaultInfoCard";
import ExpoPartecipazioniService from "services/API/expo_partecipazioni/ExpoPartecipazioniService";
import base64ToArrayBuffer from "common/script/base64ToArrayBuffer";
import { Alert, AppBar } from "@mui/material";
import MDAlert from "common/components/MD/MDAlert/MDAlert";
import SpazioComponent from "./SpazioComponent";
import AuthService from "services/API/Auth/AuthService";
import MDInput from "common/components/MD/MDInput";
import { useForm } from "react-hook-form";

function AreaFiera() {

  const { manifestazione } = useManifestazione();

  const navigate = useNavigate();
  const [part, setPart] = useState({
    idPartecipazione: 0,
    manifestazione: manifestazione,
    id_utente: AuthService.getCurrentUser().id,

    ragione_sociale: '',
    rappresentante_legale: '', indirizzo: '', civico: '', cap: '', localita: '', indirizzo2: '', provincia: '',
    piva: '', cf: '', telefono: '', fax: '', www: '', email: '', pec: '', iban: '', codice_sdi: '',
    cm_code_01: '', cm_desc_01: '', cm_code_02: '', cm_desc_02: '', cm_code_03: '', cm_desc_03: '', cm_code_04: '', cm_desc_04: '',
    responsabile_fiera: '', responsabile_fiera_telefono: '', responsabile_fiera_cell: '', responsabile_fiera_email: '',
    prodotti_servizi: '', produttore: false, concessionario: false, distributore: false, rivenditore: false, importatore: false,
    richieste: [],
    quota_iscrizione: 0,
    imponibile_ok: 0, aliquota_ok: 22, iva_ok: 0, tot_a_pagare: 0, anticipo_ok: 0, saldo: 0,
  });

  const stringa = (<MDTypography
    variant="button"
    fontWeight="regular"
    color="text"
    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
  >
    Stampa il contratto e ricaricalo firmato in tutti i suoi punti
    <MDButton onClick={() => navigate("/contratto")} color="success">Vai</MDButton>
  </MDTypography>);

  const [contratto, setContratto] = useState(stringa);
  const modulo_a = (
    <Link to="https://far.umbriafiere.it/download/A-Montaggio_Stand.pdf" target="_blank">
      <MDButton variant="outlined" color="info"  >
        A-Montaggio Stand
      </MDButton>

    </Link>

  );

  const modulo_b = (
    <Link to="https://far.umbriafiere.it/download/B-Dichiarazione_Corretto_Montaggio_Stand.pdf" target="_blank">
      <MDButton variant="outlined" color="info"  >
        B-Dichiarazione Corretto Montaggio Stand
      </MDButton>

    </Link>
  );
  const modulo_c = (
    <Link to="https://far.umbriafiere.it/download/C-Prevenzione_Incendi_Allestimenti.pdf" target="_blank">
      <MDButton variant="outlined" color="info"  >
        C-Prevenzione Incendi Allestimenti
      </MDButton>

    </Link>

  );

  const modulo_d = (
    <Link to="https://far.umbriafiere.it/download/D-Dichiarazione_di_conformita.pdf" target="_blank">
      <MDButton variant="outlined" color="info"  >
        D-Dichiarazione di conformità
      </MDButton>

    </Link>
  );
  const modulo_e = (
    <Link to="https://far.umbriafiere.it/download/E-Relazione_Tipologia_dei_Materiali.pdf" target="_blank">
      <MDButton variant="outlined" color="info"  >
        E-Relazione Tipologia dei Materiali
      </MDButton>

    </Link>

  );

  const modulo_f = (
    <Link to="https://far.umbriafiere.it/download/F-OSA.pdf" target="_blank">
      <MDButton variant="outlined" color="info"  >
        F-OSA
      </MDButton>

    </Link>
  );

  useEffect(() => {
    if (AuthService.isTokenOk()) {
    seleziona_soggetto();
    }
  }, []);

  async function seleziona_soggetto() {
    console.log("Cambiata pp->seleziono i dettagli :" + manifestazione);

    const response = await ExpoPartecipazioniService.seleziona_partecipazione(manifestazione);
    response.errors ? console.log(response.errors) : retrive(response.data);

  }
  function retrive(data) {

    setPart({
      idPartecipazione: data.idPartecipazione,
      id_expo_richiesta: data.id_expo_richiesta,
      id_utente: data.id_utente,
      nome_pdf_contratto: data.nome_pdf_contratto,
      richieste: data.richieste
    });


    console.log("selezionata  " + part.idPartecipazione);

  }

  const {
    register, //register inputs
    setValue, control,

  } = useForm({
  });


  const [titoli, setTitoli] = useState([]);


  useEffect(() => {

    console.log("Cambiata pp->riempo i campi  " + part.idPartecipazione);
    //  setRichiesta0(part.richieste[0]);
  
    let tit = []; // Oggetto per memorizzare i valori dinamici

    part.richieste.map((r) => {
      if (r.spazioAssegnatoDto != null) {
       
      
       
        const name = "spazio_" + r.idRichiesta;
        setValue(name, r.spazioAssegnatoDto.spazio);

        const num = "numero_" + r.idRichiesta;
        setValue(num, r.spazioAssegnatoDto.numero);

        const key = "key_" + r.idRichiesta; // Nome dinamico della chiave
        //tit[key] = r.spazioAssegnatoDto.spazio;
      
        if (r.spazioAssegnatoDto.spazio === "PADIGLIONE 7") {
        tit[key] = (<Link to="https://far.umbriafiere.it/download/PAD_7.pdf" target="_blank">
            <MDButton variant="outlined" color="success"  >
              Pianta {r.spazioAssegnatoDto.spazio}
            </MDButton>
          </Link>);
        }
        if (r.spazioAssegnatoDto.spazio === "PADIGLIONE 8") {
          tit[key] = (<Link to="https://far.umbriafiere.it/download/PAD_8.pdf" target="_blank">
              <MDButton variant="outlined" color="success"  >
                Pianta {r.spazioAssegnatoDto.spazio}
              </MDButton>
            </Link>);
          }
          if (r.spazioAssegnatoDto.spazio === "PADIGLIONE 9") {
            tit[key] = (<Link to="https://far.umbriafiere.it/download/PAD_9.pdf" target="_blank">
                <MDButton variant="outlined" color="success"  >
                  Pianta {r.spazioAssegnatoDto.spazio}
                </MDButton>
              </Link>);
            }
            if (r.spazioAssegnatoDto.spazio === "PADIGLIONE 10" || r.spazioAssegnatoDto.spazio === "PADIGLIONE 11"||r.spazioAssegnatoDto.spazio === "PADIGLIONE 12"
              ||(r.spazioAssegnatoDto.spazio === "PIAZZALE G" &&  r.spazioAssegnatoDto.numero>=2)
              ||(r.spazioAssegnatoDto.spazio === "PIAZZALE I" &&  r.spazioAssegnatoDto.numero>=10)
            ) {
              tit[key] = (<Link to="https://far.umbriafiere.it/download/NAN_2.pdf" target="_blank">
                  <MDButton variant="outlined" color="success"  >
                    Pianta {r.spazioAssegnatoDto.spazio}
                  </MDButton>
                </Link>);
              }
              if (r.spazioAssegnatoDto.spazio === "PIAZZALE H" || (r.spazioAssegnatoDto.spazio === "PIAZZALE G" &&  r.spazioAssegnatoDto.numero<2)
                ||(r.spazioAssegnatoDto.spazio === "PIAZZALE I" &&  r.spazioAssegnatoDto.numero<10)) {
                tit[key] = (<Link to="https://far.umbriafiere.it/download/NAN_1.pdf" target="_blank">
                    <MDButton variant="outlined" color="success"  >
                      Pianta {r.spazioAssegnatoDto.spazio}
                    </MDButton>
                  </Link>);
                }
              if (r.spazioAssegnatoDto.spazio === "PIAZZALE A") {
                tit[key] = (<Link to="https://far.umbriafiere.it/download/PIAZZALE_A.pdf" target="_blank">
                    <MDButton variant="outlined" color="success"  >
                      Pianta {r.spazioAssegnatoDto.spazio}
                    </MDButton>
                  </Link>);
                }
                if (r.spazioAssegnatoDto.spazio === "PIAZZALE B") {
                  if(r.spazioAssegnatoDto.numero<=25){
                  tit[key] = (<Link to="https://far.umbriafiere.it/download/PIAZZALE_B-1.pdf" target="_blank">
                      <MDButton variant="outlined" color="success"  >
                        Pianta {r.spazioAssegnatoDto.spazio}-1
                      </MDButton>
                    </Link>);}
                    else{
                      tit[key] = (<Link to="https://far.umbriafiere.it/download/PIAZZALE_B-2.pdf" target="_blank">
                          <MDButton variant="outlined" color="success"  >
                            Pianta {r.spazioAssegnatoDto.spazio}-2
                          </MDButton>
                        </Link>);}
                  }
                  if (r.spazioAssegnatoDto.spazio === "PIAZZALE C") {
                    tit[key] = (<Link to="https://far.umbriafiere.it/download/PIAZZALE_C.pdf" target="_blank">
                        <MDButton variant="outlined" color="success"  >
                          Pianta {r.spazioAssegnatoDto.spazio}
                        </MDButton>
                      </Link>);
                    }
                    if (r.spazioAssegnatoDto.spazio === "PIAZZALE D") {
                      tit[key] = (<Link to="https://far.umbriafiere.it/download/PIAZZALE_D.pdf" target="_blank">
                          <MDButton variant="outlined" color="success"  >
                            Pianta {r.spazioAssegnatoDto.spazio}
                          </MDButton>
                        </Link>);
                      }
                      if (r.spazioAssegnatoDto.spazio === "PIAZZALE E") {
                        tit[key] = (<Link to="https://far.umbriafiere.it/download/PIAZZALE_E.pdf" target="_blank">
                            <MDButton variant="outlined" color="success"  >
                              Pianta {r.spazioAssegnatoDto.spazio}
                            </MDButton>
                          </Link>);
                        }
                        if (r.spazioAssegnatoDto.spazio === "PIAZZALE F") {
                          if(r.spazioAssegnatoDto.numero<=5){
                          tit[key] = (<Link to="https://far.umbriafiere.it/download/PIAZZALE_F-2.pdf" target="_blank">
                              <MDButton variant="outlined" color="success"  >
                                Pianta {r.spazioAssegnatoDto.spazio}-2
                              </MDButton>
                            </Link>);}
                            else{
                              tit[key] = (<Link to="https://far.umbriafiere.it/download/PIAZZALE_F-1.pdf" target="_blank">
                                  <MDButton variant="outlined" color="success"  >
                                    Pianta {r.spazioAssegnatoDto.spazio}-1
                                  </MDButton>
                                </Link>);}
                          }
      }
      else{
        
      }
    });



    setTitoli(tit);
 


  }, [part]);



  async function vedi_contratto(idPartecipazione) {

    ExpoPartecipazioniService.download_pdf_contratto(idPartecipazione)
      .then(response => {

        return response.data;
      })
      .then(blob => {

        var arrrayBuffer = base64ToArrayBuffer(blob); //data is the base64 encoded string
        var blobb = new Blob([arrrayBuffer], { type: "application/pdf" });
        var url = URL.createObjectURL(blobb);
        var a = document.createElement('a');
        a.href = url;
        a.target = "_blank";

        a.download = part.nome_pdf_contratto;
        ;

        document.body.appendChild(a); // append the element to the dom
        a.click();
        a.remove(); // afterwards, remove the element  
      })
      .catch(error => {
        console.error(error);
      });




  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderForm titolo={"La tua area ad " + manifestazione} color="info" />

              <MDBox p={10}>

                <MDBox  >
                  {part.richieste.map((richiesta) => (
                    <MDBox>
                      <SpazioComponent
                        key={richiesta.idRichiesta}
                        id={richiesta.idRichiesta}
                        richiesta={richiesta}
                      />
                    {richiesta.spazioAssegnatoDto != null &&
                    <MDBox>

                      <MDBox mb={2} mt={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr ' }}>
                        <MDInput type="text" label="Spazio" name={"spazio_" + richiesta.idRichiesta} fullWidth
                          {...register("spazio_" + richiesta.idRichiesta)} InputLabelProps={{ shrink: true }} disabled />

                        <MDInput type="text" label="Numero" name={"numero_" + richiesta.idRichiesta} fullWidth
                          {...register("numero_" + richiesta.idRichiesta)} InputLabelProps={{ shrink: true }} disabled/>
                      </MDBox>
                      <MDBox mb={2} mt={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr ' }}>
                      <DefaultInfoCard
                          color="success"
                          icon="map"
                          title={titoli["key_"+richiesta.idRichiesta]}
                          description={"Qui puoi vedere e stampare la tua Area Assegnata"}
                                               />

                        <DefaultInfoCard
                        
                          color="success"
                          icon="map"
                          title={<Link to="https://far.umbriafiere.it/download/PLANIMETRIA_GENERALE.pdf" target="_blank">
                          <MDButton variant="outlined" color="success"  >
                            Pianta completa area fiera
                          </MDButton>
                        </Link>}
                          description={"Qui puoi vedere e stampare la pianta completa della fiera"}
                          value=""
                        />
                      </MDBox>
                      </MDBox>}
                    </MDBox>
                  ))}
                </MDBox>
                  <MDBox>
                  <DefaultInfoCard
                        
                        color="success"
                        icon="alarmOn"
                        title={<Link to="https://far.umbriafiere.it/download/promemoria.pdf" target="_blank">
                        <MDButton variant="outlined" color="success"  >
                          Promemoria per gli espositori
                        </MDButton>
                      </Link>}
                        description={"Promemoria importante per tutti gli espositori!"}
                        value=""
                      />
                  </MDBox>
              </MDBox>


            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}



export default AreaFiera;
