import { useEffect, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";


// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import Footer from "layouts/components/Footer";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";

import HeaderForm from "layouts/components/HeaderForm";
import AuthService from "services/API/Auth/AuthService";
import ComunicazioniService from "services/API/Comunicazioni/ComunicazioniService";
import MessaggiTableRead from "./MessaggiTableRead";

function ElencoMyMessaggi() {


  const [messaggi, setMessaggi] = useState([]);

  useEffect(() => {
    retrieveMessaggi();
  }, []);



  const retrieveMessaggi = () => {

    if (AuthService.isTokenOk()) {
      const mioid = AuthService.getCurrentUser().id;
      ComunicazioniService.getMyMessaggi(mioid)
        .then(response => {
          setMessaggi(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    }
  };



  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderForm titolo="Messaggi Ricevuti " add="" chiamante="messaggi_expo" modo="vis" />
              <MessaggiTableRead messaggi={messaggi} chiamante="messaggi_expo"></MessaggiTableRead>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ElencoMyMessaggi;
