import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";

import MDTypography from "common/components/MD/MDTypography";


// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";


import Alert from '@mui/material/Alert';
import FormControl from "@mui/material/FormControl";


import loading_image from 'common/assets/images/icons/loading-green-loading.gif'
// Data
import ExpoRichiesteService from "services/API/ExpoRichieste/ExpoRichiesteService";
import UtilityService from "services/API/utility/UtilityService";

import { Chip} from "@mui/material";
import ExpoRichiesteTable from "./ExpoRichiesteTable";
import { ColumnFilter } from "common/components/implemented/Tables/DataTable/ColumnFilter";

import { SelectColumnFilterTipo } from "./SelectColumnFilterTipo";
import { CheckFilter, NoneFilter } from "./NoneFilter";
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import { SelectColumnFilterStatoOK } from "./SelectColumnFilterStatoOK";
import { SelectColumnFilterMsg } from "./SelectColumnFilterMsg";
import base64ToArrayBuffer from "common/script/base64ToArrayBuffer";
import ComunicazioniService from "services/API/Comunicazioni/ComunicazioniService";
import { useManifestazione } from "common/context/GlobalContext";
import ExpoPartecipazioniService from "services/API/expo_partecipazioni/ExpoPartecipazioniService";


function ElencoRichieste() {

  const [proposte, setProposte] = useState([]);
  // const  {manifestazione}= useManifestazione();
 
  const  manifestazione= "Agriumbria 2025";
  const [loading, setLoading] = useState(true);


  //per passarmi la variabiule se ho salvato e far comparire l'alert
  const location = useLocation();
  //console.log(location);

  const { state } = location != null ? location : { state: { submitted: false, salvato: 'NO', indietro: false } }; // Read values passed on state


  async function download_pdf_allegato(idAllegato, nome_file) {

    ComunicazioniService.download_allegato(idAllegato)
      .then(response => {
 
        return response.data;
      })
      .then(blob => {

        var arrrayBuffer = base64ToArrayBuffer(blob); //data is the base64 encoded string
        var blobb = new Blob([arrrayBuffer], { type: "application/pdf" });
        var url = URL.createObjectURL(blobb);
        var a = document.createElement('a');
        a.href = url;
        a.target = "_blank";

        a.download = nome_file;
        ;

        document.body.appendChild(a); // append the element to the dom
        a.click();
        a.remove(); // afterwards, remove the element  
      })
      .catch(error => {
        console.error(error);
      });




  }
  async function vedi_contratto(id_partecipazione) {
    console.log("download_pdf :" + id_partecipazione);
    if (id_partecipazione != 0) {
        let filename = 'ContrattoAgriumbria2025.pdf';

        ExpoPartecipazioniService.genera_pdf_contratto(id_partecipazione)
            .then(response => {
                /*  const disposition = response.headers['content-disposition'];
                 filename = disposition.split(/;(.+)/)[1].splitvv(/=(.+)/)[1];
                  if (filename.toLowerCase().startsWith ("utf-8''"))
                     filename = decodeURIComponent(filename.replace("utf-8''", ''));
                  else
                     filename = filename.replace(/['"]/g, '');*/
                return response.data;
            })
            .then(blob => {

                var arrrayBuffer = base64ToArrayBuffer(blob); //data is the base64 encoded string
                var blobb = new Blob([arrrayBuffer], { type: "application/pdf" });
                var url = URL.createObjectURL(blobb);
                var a = document.createElement('a');
                a.href = url;
                a.target = "_blank";
                a.download = filename;
                document.body.appendChild(a); // append the element to the dom
                a.click();
                a.remove(); // afterwards, remove the element  
            })
            .catch(error => {
                console.error(error);
            });

        /*           let filename = 'StampaRichiesta.pdf';
                   var url=    ERService.download_pdf(soggetto.idRichiesta);
       
                         var a = document.createElement('a');
                         a.href = url;
                         a.target = "_blank";
                         a.download = filename;
                         document.body.appendChild(a); // append the element to the dom
                         a.click();
                         a.remove();*/

    }
}
async function vedi_anteprima_contratto(idRichiesta) {
  console.log("download_pdf :" + idRichiesta);
  if (idRichiesta != 0) {
      let filename = 'ContrattoAgriumbria2025.pdf';

      ExpoRichiesteService.genera_pdf_contratto(idRichiesta)
          .then(response => {
            
              return response.data;
          })
          .then(blob => {

              var arrrayBuffer = base64ToArrayBuffer(blob); //data is the base64 encoded string
              var blobb = new Blob([arrrayBuffer], { type: "application/pdf" });
              var url = URL.createObjectURL(blobb);
              var a = document.createElement('a');
              a.href = url;
              a.target = "_blank";
              a.download = filename;
              document.body.appendChild(a); // append the element to the dom
              a.click();
              a.remove(); // afterwards, remove the element  
          })
          .catch(error => {
              console.error(error);
          });


  }
}



  useEffect(() => {
  
      console.log("FILTRO");
      setProposte([]);
      setLoading(true);

      ExpoRichiesteService.getAllRichieste(manifestazione, 'INVIATA', 'OK')
        .then(response => {
          setProposte(response.data);
          setLoading(false);
        })
        .catch(e => {
          console.log(e);
          setLoading(false);
        });
    
  }, []);

  


  const ppAttributes = [];

  if (proposte.length > 0) {
    proposte.forEach(prop => {

      let action = (
        <MDBox>

        <Link to="/expo_richiestaMessage" state={{id_proposta: prop.idRichiesta,chiamante:'expo_richieste_ok_admin'}}
          >
            <MDButton variant="outlined" color={"success"} iconOnly={true}  >
           < AttachEmailIcon/>
            </MDButton>   </Link>
          <Link to="/expo_richiestaPDFVis" state={{ modo: 'vis', soggetto: prop, subject: prop.manifestazione,chiamante:'expo_richieste_ok_admin' }}
          >
            <MDButton variant="outlined" color={"success"} iconOnly={true} >
              <Icon>visibility</Icon>
            </MDButton>
          </Link>
        </MDBox>
      );
    
      let lettera_a = "";
      if (prop.id_allegato_esito != null && prop.nome_file_esito) {
  
          lettera_a = (
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              onClick={() => download_pdf_allegato(prop.id_allegato_esito, prop.nome_file_esito)}
            >
              {prop.nome_file_esito}
            </MDTypography>
          );
        }
      let msg_a="DA SCRIVERE";
      if (prop.id_messaggio != null && prop.id_messaggio != 0) {
        msg_a="DA INVIARE";
        if (prop.inviato) {
          msg_a = "INVIATO IL " + prop.string_data_invio;
        }
      }




let part="NO";

if(prop.id_partecipazione!=null && prop.id_partecipazione!=0){
  part= (
    <MDTypography
      variant="button"
      fontWeight="regular"
      color="text"
      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
      onClick={() =>vedi_contratto(prop.id_partecipazione)}
    >
     Anteprima Contratto
    </MDTypography>
  );
}else{
  part= (
  <MDTypography
  variant="button"
  fontWeight="regular"
  color="text"
  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
  onClick={() =>vedi_anteprima_contratto(prop.idRichiesta)}
>
 Anteprima Contratto
</MDTypography>)


}

      ppAttributes.push({
        er:prop,
        mqr:prop.mqr,
        protocollo: prop.protocollo,
        azienda: prop.ragione_sociale,
        data_invio: prop.string_data_agg,
        data_um: prop.string_data_agg_accettazione,
        note: prop.note,
        cm_string: prop.cm_string,
        stato: prop.stato_accettazione,
        av: prop.anticipo_controllato_string,
        etichetta: prop.etichetta,
        color:prop.color,
        action: action,
        esigenze_espositive: prop.esigenze_espositive,
        tipologia_espositiva_string: prop.tipologia_stand,
        msg_a:msg_a,
        lettera_a:lettera_a,
        partecipazione:part,
      })
    });
  }



  const ppTableData = {
    rows: ppAttributes,

    columns: [
    
      { Header: "Protocollo", accessor: "protocollo", Filter: ColumnFilter, width: "2%", align: "left", id: "proposte_ok_0"},
      { Header: "Azioni", accessor: "action", Filter: NoneFilter, align: "center", enableGlobalFilter: false, id: "proposte_ok_1",disableSortBy:true},
      { Header: "Azienda", accessor: "azienda", Filter: ColumnFilter, align: "left", with: "50%", id: "proposte_ok_2" },
      { Header: "Codice Merceologico", accessor: "cm_string", Filter: ColumnFilter, width: "10%", align: "left", id: "proposte_ok_3" },
      {
        Header: "Tipologia Espositiva Richiesta", accessor: "tipologia_espositiva_string",
        id: "proposte_ok_4",

        Filter: SelectColumnFilterTipo,
        Cell: ({ row }) => {
          return (
            <MDBox>
              <MDBox> <MDTypography variant="button"
                fontWeight="regular"
                color="text">{row.original.tipologia_espositiva_string}</MDTypography></MDBox>
              {row.original.esigenze_espositive &&
                <MDBox>  <MDTypography variant="button"
                  fontWeight="regular"
                  color="text">*** {row.original.esigenze_espositive.substring(0, 20)}...</MDTypography> </MDBox>}
              {row.original.note &&
                <MDBox>  <MDTypography variant="button"
                  fontWeight="regular"
                  color="text">*** {row.original.note.substring(0, 20)}...</MDTypography> </MDBox>}
            </MDBox>

          );
        },
        width: "15%", align: "left"
      },
      { Header: "Mq richiesti", accessor: "mqr", Filter: NoneFilter, align: "left", width: "5%", id: "proposte_ok_7" },
      { Header: "Stato", accessor: "stato", Filter: SelectColumnFilterStatoOK,filter: "exactMatch", align: "left", width: "5%", id: "proposte_ok_5" },
      { Header: "Anticipo Controllato", accessor: "av", Filter: ColumnFilter, align: "left", width: "5%", id: "proposte_ok_6" },
      { Header: "Etichetta", accessor: "etichetta", Filter: ColumnFilter,
        Cell: ({ row }) => {
          return (
            <Chip label={row.original.etichetta} color={row.original.color} />

          );
        },
        align: "left", width: "5%", id: "proposte_ok_8" },
        { Header: "Lettera Accettazione", accessor: "lettera_a", Filter: NoneFilter, width: "2%", align: "left", id: "proposte_ok_9" },
        { Header: "Msg Accettazione", accessor: "msg_a", Filter:  SelectColumnFilterMsg, width: "2%", align: "left", id: "proposte_ok_10" },
        { Header: "A.R.", accessor: "partecipazione", Filter:  ColumnFilter, width: "1%", align: "left", id: "proposte_ok_11" },

    ],



  };

  const mypagination = { color: 'success', variant: "contained" };

 


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderForm titolo="Proposte di Partecipazione Accettate" add="" modo="vis" />
              <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">

                {(state != null && state.submitted && (state.salvato === 'INS' || state.salvato === 'MOD')) &&

                  <Alert severity="success">Dati Proposta salvati con successo!</Alert>}
                {(state != null && state.submitted && (state.salvato === 'SENT')) &&

                  <Alert severity="success">Proposta di partecipazione inviata con successo!</Alert>}
                {(state != null && state.submitted && state.salvato === 'DEL') &&

                  <Alert severity="success">Proposta eliminata con successo!</Alert>}
              </MDBox>


              <MDBox pt={3}>
                <ExpoRichiesteTable canSearch={true}
                  table={ppTableData}
                  nome_tabella="proposte_ok"
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={mypagination}
                  noEndBorder
                  responsive={true}
                  first_load={state == null || !state.indietro}
                  filtro_server={true}
                  canExport={true}
                  canSend={true}
                  enableColumnFilters={true}
                  chiamante='expo_richieste_ok_admin'

                />
              </MDBox>
              {loading &&
                <MDBox opacity={1} textAlign="center">
                  <img src={loading_image} alt="loading" width={100} />
                </MDBox>
              }

              {(proposte == null || proposte.length === 0) &&
                <MDBox style={{ height: "200px" }} textAlign='center'>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    Non ci sono Proposte di partecipazione in archivio. Clicca su Nuovo per crearne una!
                  </MDTypography></MDBox>
              }
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}



export default ElencoRichieste;
