import axios from 'axios';
import authHeader from 'services/API/Auth/AuthHeader';
import AuthService from 'services/API/Auth/AuthService.js';
import authHeaderMultipart from '../Auth/AuthHeaderMultipart';
import authHeaderPDF from '../Auth/AuthHeaderPDF';

const getMyRichieste=()=> {
    console.log("getMyRichieste "+AuthService.getCurrentUser().id);
   
    return axios.get(axios.defaults.baseURL + `/expo_richieste/all/${AuthService.getCurrentUser().id}`,{headers: authHeader()});
}


const create = (data) => {   
    return axios.post(axios.defaults.baseURL + '/expo_richieste/ins',data, {
        headers: authHeader()
    })  
   };
   const update = (id, data) => {
    console.log("update= "+id);
    return axios.post(axios.defaults.baseURL + `/expo_richieste/upd/${id}`, data,{headers: authHeader()});
  };

  const remove = (id) => {
    console.log("remove= "+id);
    return axios.post(axios.defaults.baseURL + `/expo_richieste/remove/${id}`,null,{headers: authHeader()});
  };
  const invia = (id, data) => {
    console.log("invia= "+id);
    return axios.post(axios.defaults.baseURL + `/expo_richieste/send/${id}`, data,{headers: authHeaderMultipart()});
  };

const seleziona_dattagli =(id)=>{

    console.log("seleziona_dettagli= "+id);
    return axios.get(axios.defaults.baseURL + `/anagrafica/seleziona_dettagli/${id}`,{headers: authHeader()});

}
const seleziona =(id)=>{

    console.log("seleziona= "+id);
    return axios.get(axios.defaults.baseURL + `/expo_richieste/seleziona/${id}`,{headers: authHeader()});
}

const seleziona2 =(id)=>{

    console.log("seleziona2= "+id);
    return axios.get(axios.defaults.baseURL + `/expo_richieste/seleziona2/${id}`,{headers: authHeader()});
}

const selezionaPDF =(id)=>{

    console.log("seleziona_pdf= "+id);
 const response= axios.get(axios.defaults.baseURL + `/expo_richieste/seleziona_pdf/${id}`,{headers: authHeader()}, {responseType: 'blob'},);
console.log("RESPONSE="+response);
return response;
}
const seleziona_esito =(id)=>{

    console.log("selezionaAllegato= "+id);
 const response= axios.get(axios.defaults.baseURL + `/expo_richieste/seleziona_esito/${id}`,{headers: authHeader()}, {responseType: 'blob'},);
console.log("RESPONSE="+response);
return response;
}

const download_pdf =(id)=>{

    console.log("download_pdf= "+id);
const response= axios.get(axios.defaults.baseURL + `/expo_richieste/jasper/${id}`,{headers: authHeader()}, {responseType: 'blob'},);

return response;
}
const esporta_xls =(man, stato,stato_a)=>{

    console.log("esporta_xls");
const response= axios.get(axios.defaults.baseURL + `/expo_richieste/excel/${man}/${stato}/${stato_a}`,{headers: authHeader()}, {responseType: 'blob'},);

return response;
}

const esporta_xls_row=(righe)=>{


    //console.log(righe);



    const response= axios.post(axios.defaults.baseURL + `/expo_richieste/excel_row`, righe,{headers: authHeader()}, {responseType: 'blob'});

    return response;

}

const getAllRichieste=(man, stato,stato_a)=> {
     console.log("getAllRichieste man="+man+" stato="+stato+" stato_a="+stato_a);
     return axios.get(axios.defaults.baseURL + `/expo_richieste/all/${man}/${stato}/${stato_a}`,  {headers: authHeader()});
 }
 const salva_accettazione = (id, data) => {
    console.log("salva_accettazione= "+id);
   // console.log(data);
    return axios.post(axios.defaults.baseURL + `/expo_richieste/accettazione/${id}`, data,{headers: authHeader()});
  };

  const abilita_area_riservata = (datas) => {
    console.log(datas);
         return axios.post(axios.defaults.baseURL + '/expo_richieste/abilita_ar',datas, {headers: authHeader()
   })  
  };

  
const isPartecipante= async (manifestazione)=>{

    //console.log(":::"+AuthService.isLoggedIn()+":::");

  if(!AuthService.isLoggedIn()|| !AuthService.isTokenOk()) 
    
    return false;


    console.log("manifestazione "+ manifestazione+" id_utente="+AuthService.getCurrentUser().id);
   
    const response = await axios.get(axios.defaults.baseURL + `/expo_partecipazioni/isPartecipante/${AuthService.getCurrentUser().id}/${manifestazione}`,{headers: authHeader()});
    return response.data;
}
const genera_pdf_contratto =(id)=>{

    console.log("genera pdf contratto= "+id);
const response= axios.get(axios.defaults.baseURL + `/expo_richieste/anteprima_contratto/${id}`,{headers: authHeader()}, {responseType: 'blob'},);

return response;
}

const ExpoRichiesteService = {
    getMyRichieste,
    getAllRichieste,
    create,
    update,invia,
    remove,
    seleziona,seleziona2,
    seleziona_dattagli,selezionaPDF,esporta_xls_row,
    download_pdf,esporta_xls,salva_accettazione,
    abilita_area_riservata,isPartecipante,genera_pdf_contratto,seleziona_esito
}

export default ExpoRichiesteService;