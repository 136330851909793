/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState, useRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDTypography from "common/components/MD/MDTypography";
import MDInput from "common/components/MD/MDInput";
import MDPagination from "common/components/MD/MDPagination/MDPagination";

// Material Dashboard 2 React example components
import DataTableHeadCell from "common/components/implemented/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "common/components/implemented/Tables/DataTable/DataTableBodyCell";
import FilterListIcon from '@mui/icons-material/FilterList';
import { Accordion, AccordionDetails, AccordionSummary, Chip, IconButton } from "@mui/material";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
// Componente per il filtro globale
const GlobalFilter = ({ filter, setFilter }) => {
  return (

    <MDInput
    placeholder="Ricerca veloce..."
    value={filter || ""}
    size="small"
    fullWidth
    onChange={(e) => setFilter(e.target.value)}
  />
    
  );
};


function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  nome_tabella="table",
  first_load=true,
  filtro_server,panel_search,
  filtro_0,filtro_1,filtro_2,
  canExport, esporta_xls
}) {

  

  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const [pageIndex, setPageIndex] = useState(
    JSON.parse(sessionStorage.getItem(nome_tabella+"_pageIndex")) || 0

  );
  // Recupera il filtro globale da sessionStorage o imposta uno stato vuoto
 /* const [globalFilter, setGlobalFilter] = useState(
  sessionStorage.getItem({nome_tabella}+"_globalFilter")) || ''
  );*/
 

 useEffect(() => {  
console.log("FIRST LOAD="+first_load);
  if(first_load) resetState();

 }, []);

 /*useEffect(() => {  
  console.log("RESETTO");
  resetState();
  
   }, [filtro_0,filtro_1,filtro_2]);*/

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex,globalFilter: (sessionStorage.getItem(nome_tabella+"_globalFilter")=="undefined" )? "":sessionStorage.getItem(nome_tabella+"_globalFilter")} },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
   setGlobalFilter,
    state: { pageIndex: currentPageIndex ,pageSize,globalFilter}
  } = tableInstance;

   // Salva lo stato della pagina corrente in sessione ogni volta che cambia
   useEffect(() => {
    sessionStorage.setItem(nome_tabella+"_pageIndex", JSON.stringify(currentPageIndex));
  }, [currentPageIndex]);
  
  
  useEffect(() => {
    
    if(globalFilter!=null && globalFilter!=''){
    sessionStorage.removeItem(nome_tabella+'_pageIndex');
   setPageIndex(0);}
    sessionStorage.setItem(nome_tabella+"_globalFilter", globalFilter);
  }, [globalFilter]);


  //console.log(tableInstance.state) //access the entire internal state
  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={currentPageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || '');
   // currentGlobalFilter=value||'';
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

   // Setting the entries starting point
   const entriesStart = currentPageIndex === 0 ? currentPageIndex + 1 : currentPageIndex * pageSize + 1;

   // Setting the entries ending point
   let entriesEnd;
 
   if (currentPageIndex === 0) {
     entriesEnd = pageSize;
   } else if (currentPageIndex === pageOptions.length - 1) {
     entriesEnd = rows.length;
   } else {
     entriesEnd = pageSize * (currentPageIndex + 1);
   }
   const resetState = () => {
    console.log("RESETTO LA SESSIONE x "+nome_tabella)
    sessionStorage.removeItem(nome_tabella+'_pageIndex');
    sessionStorage.removeItem(nome_tabella+'_globalFilter');
    
   setPageIndex(0);
   
   setGlobalFilter('');
   console.log("RESETTO LA SESSIONE x "+nome_tabella+" page_index="+pageIndex);
  };



  return (

    <TableContainer sx={{ boxShadow: "none" }}>
      {entriesPerPage || canSearch ? (
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>

          <Accordion fullWidth sx={{ boxShadow: "none" }}>
            <MDBox sx={{ display: 'grid', gap: 1, gridTemplateColumns: '3fr 12fr 1fr 1fr' }}>
             
                {entriesPerPage && (
                  <MDBox display="flex" alignItems="center">
                    <Autocomplete
                      disableClearable
                      value={pageSize.toString()}
                      options={entries}
                      onChange={(event, newValue) => {
                        setEntriesPerPage(parseInt(newValue, 10));
                      }}
                      size="small"
                      sx={{ width: "5rem" }}
                      renderInput={(params) => <MDInput {...params} />}
                    />
                    <MDTypography variant="caption" color="secondary">
                      &nbsp;&nbsp;entries per page
                    </MDTypography>
                  </MDBox>
                )}
                {canSearch && (
                  <MDBox width="12rem" ml="auto" sx={{ display: 'grid', gap: 1, gridTemplateColumns: ' 1fr 3fr' }} >
               {/*   <MDInput
                      placeholder="Ricerca veloce..."
                      value={search}
                      size="small"
                      fullWidth
                      onChange={({ currentTarget }) => {
                        setSearch(search);
                        onSearchChange(currentTarget.value);
                      }}
                    />*/}
                      <IconButton variant="outlined"  color="success" onClick={resetState}>
                      <CleaningServicesIcon fontSize="small" /></IconButton>
                           
                       <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                  </MDBox>
                )}

    {/*      <AccordionSummary               
               expandIcon={<FilterListIcon color="success" mb={5} />}
               aria-controls="panel1-content"
               id="panel1-header"
               
             >
              {filtro_0 &&
               <Chip label={filtro_0} color="success" variant="outlined" pb={2} />
              }
               {filtro_1 &&
               <Chip label={filtro_1} color="success" variant="outlined" mb={5}/>
}
{filtro_2 &&
               <Chip label={filtro_2} color="success" variant="outlined" mb={5} />
     }
          </AccordionSummary>*/}

              {canExport && (
                <MDBox mb={1} >
                  <IconButton variant="outlined" fontSize="medium" color="success" onClick={esporta_xls}>
                    <FileDownloadOutlinedIcon /></IconButton></MDBox>
              )}
            </MDBox>
            <AccordionDetails>
             {panel_search}
            </AccordionDetails>
          </Accordion>




        </MDBox>
      ) : null}
      <Table {...getTableProps()}  >
        <MDBox component="thead">
          {headerGroups.map((headerGroup, key) => (
            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, idx) => (
                <DataTableHeadCell
                  key={idx}
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow key={key} {...row.getRowProps()}>
                {row.cells.map((cell, idx) => (

                  <DataTableBodyCell
                    key={idx}
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Showing {entriesStart} to {entriesEnd} of {rows.length} entries
            </MDTypography>
          </MDBox>
        )}
        {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <MDPagination item onClick={() => previousPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[currentPageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <MDPagination item onClick={() => nextPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default DataTable;
