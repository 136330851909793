/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, forwardRef } from "react";
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';

// react-router-dom components
import { useLocation, useNavigate } from "react-router-dom";

import AuthService from "services/API/Auth/AuthService";
import ComuniService from 'services/API/Comuni/ComuniService.js';
import ERService from 'services/API/ExpoRichieste/ExpoRichiesteService.js';
import UtilityService from "services/API/utility/UtilityService";

// @mui material components
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Grid from "@mui/material/Grid";

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import { pink } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ReactHookFormCheckBox from "common/components/mycomponents/ReactHookFormCheckBox";
import ReactHookFormSelect from "common/components/mycomponents/ReactHookFormSelect";
import DashboardLayout from "layouts/DashboardLayout";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";


// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";
import MDInput from "common/components/MD/MDInput";
import MDTypography from "common/components/MD/MDTypography";



// Authentication layout components piu largo

// Images

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

//validazione lato client
import { yupResolver } from '@hookform/resolvers/yup';
import ExpoRichiesteStepper from "pages/riservata/expo_richieste/ExpoRichiesteStepper";
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { TextField } from "@mui/material";
import useDebounce from "common/custom_hook/useDebounce";
import ExpoPartecipazioniService from "services/API/expo_partecipazioni/ExpoPartecipazioniService";
import { useManifestazione } from "common/context/GlobalContext";

function DatiCatalogoEdit() {

  const color_pink = pink[300];


  const location = useLocation();
  const navigate = useNavigate();
  const { manifestazione } = useManifestazione();
  const [part, setPart] = useState({
    idPartecipazione: 0,
    id_expo_richiesta: 0,

    datiC: [{}]
  });
  // user state for form
  const [listDatiC, setListDatiC] = useState([{
    idCatalogo: 0,
    id_partecipazione: 0,
    espositore_azienda: 0,
    ragione_sociale: '',
    indirizzo: '', civico: '', cap: '', localita: '', indirizzo2: '', provincia: '',
    piva: '', cf: '', telefono: '', fax: '', www: '', email: '', pec: '',
    cm_code_01: '', cm_desc_01: '', cm_code_02: '', cm_desc_02: '',
    prodotti_esposti: '', ordine: 0
  }]);

  const [lista_loc, setLista_loc] = useState([
    { cap: '', localita: '', sigla_provincia: '', denominazione_provincia: '' },
  ]);
  const [lista_codici_m, setLista_codici_m] = useState([
    { codice: '', descrizione: '' },
  ]);


  useEffect(() => {
    console.log("carico i codici merceologici");

    async function fetchInitialData2() {
      const response = await UtilityService.getAllCodiciM();
      response.errors ? console.log(response.errors) : setLista_codici_m([...response.data]);
    }
    fetchInitialData2();

  }, []);




  /* ----------------------------FUNZIONALITA PAGINA------------------------------------------------- */
  const [query_cap, setQuery_cap] = useState('');
  const debouncedQuery = useDebounce(query_cap, 500); // Debounce di 500ms


  // Effettua la ricerca quando `debouncedQuery` cambia
  useEffect(() => {
    if (debouncedQuery) {
      console.log(`Effettua la ricerca per: ${debouncedQuery}`);
      // Qui potresti invocare un'API di ricerca o filtrare i dati localmente
    //  console.log("retrive");
      setLista_loc([
        { cap: '', localita: '', sigla_provincia: '', denominazione_provincia: '' },
      ]);
     // setValue('localita', '');

      ComuniService.getAllComuni(query_cap)
        .then(response => {
          setLista_loc([...response.data]);
          //   console.log(response.status);
              if (response.status === 200) {
                console.log(response.data[0].localita);
               /* setValue('provincia', response.data[0].sigla_provincia.toUpperCase());
           
              /*  if (prop.localita != null) {
                  console.log("LOC=" + prop.localita);
                  setValue('localita', prop.localita);
    
                }*/
              }


        })
        .catch(e => {
          console.log(e);
        });
    }
  }, [debouncedQuery]);


function aggiungi_azienda(){

  const size =listDatiC.length;

  const newAzienda = {   idCatalogo: 0,
    id_partecipazione: part.idPartecipazione,
    espositore_azienda: 1,
    ragione_sociale: '',
    indirizzo: '', civico: '', cap: '', localita: '', indirizzo2: '', provincia: '',
    piva: '', cf: '', telefono: '', fax: '', www: '', email: '', pec: '',
    cm_code_01: '', cm_desc_01: '', cm_code_02: '', cm_desc_02: '',
    prodotti_esposti: '', ordine: size };

  // Aggiornare lo stato immutabilmente
  setListDatiC(prev => [...prev, newAzienda]);

}


  /* ----------------------------CARICAMENTO E RIEMPIMENTO CAMPI------------------------------------------------- */



  useEffect(() => {
    seleziona_soggetto();

  }, []);

  async function seleziona_soggetto() {
    //console.log("Cambiata pp->seleziono i dettagli :"+manifestazione);

    const response = await ExpoPartecipazioniService.seleziona_partecipazione(manifestazione);
    response.errors ? console.log(response.errors) : retrive(response.data);

  }

  function retrive(data) {
    console.log(data);
    //console.log("retrive =" + data.idRichiesta);

    setPart({
      idPartecipazione: data.idPartecipazione,
      id_expo_richiesta: data.id_expo_richiesta,
      id_utente: data.id_utente,
      datiC: data.record_catalogo
    });

    console.log("selezionata  " + part.idPartecipazione);

  }


  useEffect(() => {

    console.log("Cambiata pp->riempo i campi  " + part.idPartecipazione);

    if (part != null && part.datiC != null &&  part.datiC.length>0){
      const dati0 = part.datiC.filter(d => d.espositore_azienda === 0)[0];
if(dati0!=null ){
      // reset form with user data
      setValue("ragione_sociale", dati0.ragione_sociale);
      setValue("piva", dati0.piva);
      setValue("cf", dati0.cf);

      setValue("indirizzo", dati0.indirizzo);
      setValue("indirizzo2", dati0.indirizzo2);
      setValue("civico", dati0.civico);
      setValue("cap", dati0.cap);

      setQuery_cap(dati0.cap);


      setValue("localita", dati0.localita);
      setValue("provincia", dati0.provincia);

      setValue("telefono", dati0.telefono);
      setValue("fax", dati0.fax);
      setValue("www", dati0.www);
      setValue("email", dati0.email);

      setValue("pec", dati0.pec);
      setValue("codice_merceologico_01", dati0.cm_01);
      setValue("codice_merceologico_02", dati0.cm_02);


      setValue("prodotti_esposti", dati0.prodotti_esposti);

      setListDatiC(part.datiC.filter(d => d.espositore_azienda == 1));
    
}
    }
  }, [part]);

useEffect(()=>{
  listDatiC.map((azienda) => {
    // reset form with user data
    setValue("ragione_sociale_"+azienda.ordine, azienda.ragione_sociale);
    setValue("piva_"+azienda.ordine, azienda.piva);
    setValue("cf_"+azienda.ordine, azienda.cf);
    
    setValue("indirizzo_"+azienda.ordine, azienda.indirizzo);
    setValue("indirizzo2_"+azienda.ordine, azienda.indirizzo2);
    setValue("civico_"+azienda.ordine,azienda.civico);
    setValue("cap_"+azienda.ordine, azienda.cap);
    
    setQuery_cap(azienda.cap);
    
    
    setValue("localita_"+azienda.ordine, azienda.localita);
    setValue("provincia_"+azienda.ordine,azienda.provincia);
    
    setValue("telefono_"+azienda.ordine,azienda.telefono);
    setValue("fax_"+azienda.ordine, azienda.fax);
    setValue("www_"+azienda.ordine, azienda.www);
    setValue("email_"+azienda.email, azienda.email);
    
    setValue("pec_"+azienda.pec, azienda.pec);
    setValue("codice_merceologico_01_"+azienda.ordine, azienda.cm_01);
    setValue("codice_merceologico_02_"+azienda.ordine, azienda.cm_02);
    
    
    setValue("prodotti_esposti_"+azienda.ordine, azienda.prodotti_esposti);
      }
  )




},[listDatiC]);


  /* ----------------------------VALIDAZIONE E SALVATAGGIO----------------------------------------------------------------------- */

  const validationSchema = Yup.object().shape({
    ragione_sociale: Yup.string().required('Nome Azienda richiesto').max(150, 'Lunghezza massima raggiunta'),
    cf: Yup.string()
      .required('CF richiesto'),
    piva: Yup.string()
      .required('Partita IVA richiesto'),
    email: Yup.string()
      .required('Email richiesta')
      .email('Email non valida'),
    pec: Yup.string()
      .required('Pec richiesta')
      .email('Email non valida'),
    indirizzo: Yup.string()
      .required('Indirizzo richiesto')
      .max(70, 'Inserire soltanto l\'indirizzo (via/piazza/vocabolo/...'),
    indirizzo2: Yup.string()
      .max(30, 'Numero massimo di caratteri raggiunto'),
    civico: Yup.string()
      .required('Numero civico richiesto')
      .max(10, 'Lunghezza massima raggiunta'),
    cap: Yup.string()
      .required('Cap richiesto')
      .max(10, 'Lunghezza massima raggiunta'),
    telefono: Yup.string().required('Telefono richiesto')
      .max(20, 'Lunghezza massima raggiunta'),
    localita: Yup.string()
      .required('Località richiesto')
      .max(100, 'Lunghezza massima raggiunta'),
    provincia: Yup.string()
      .required('Sigla Provincia Richiesta')
      .max(4, 'Inserire la sigla della provincia'),
    codice_merceologico_01: Yup.string()
      .required('Codice merceologico principale richiesto')
      .min(2, 'Codice merceologico principale richiesto'),
      prodotti_esposti: Yup.string()
      .required('E\' richiesta una descrizione dettagliata dei prodotti o servizi venduti ed esposti'),
  });
  /* ----------------------------SALVATAGGIO E REGISTRAZIONE------------------------------------------------- */

  const {
    register, //register inputs
    reset, setValue, control,
    handleSubmit,//handle form submit
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });



  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const inserisci_pp = (formData) => {

    setError(null);

   
    let cm1 = null, cm2 = null;
    let c1 = lista_codici_m.find(c => c.codice === formData.codice_merceologico_01);
    if (c1 != null) cm1 = c1.codice;
    let c2 = lista_codici_m.find(c => c.codice === formData.codice_merceologico_02);
    if (c2 != null) cm2 = c2.codice;

    let dati0;

     dati0 = part.datiC.filter(d => d.espositore_azienda == 0)[0];


    console.log("dati0 prima delle modifiche=");
    console.log(dati0);

    if (dati0 == undefined) {
    
      dati0 = {
        idCatalogo: 0,
        id_partecipazione: part.idPartecipazione,
        espositore_azienda: 0,
        ragione_sociale: '',
        indirizzo: '', civico: '', cap: '', localita: '', indirizzo2: '', provincia: '',
        piva: '', cf: '', telefono: '', fax: '', www: '', email: '', pec: '',
        cm_code_01: '', cm_desc_01: '', cm_code_02: '', cm_desc_02: '',
        prodotti_esposti: '',
      }
      
    //part.datiC.push(dati0);
    }





    dati0.ragione_sociale = formData.ragione_sociale;
    dati0.indirizzo = formData.indirizzo;
    dati0.civico = formData.civico;
    dati0.cap = formData.cap;
    dati0.localita = formData.localita;
    dati0.indirizzo2 = formData.indirizzo2;
    dati0.provincia = formData.provincia;
    dati0.piva = formData.piva;
    dati0.cf = formData.cf;
    dati0.telefono = formData.telefono;
    dati0.fax = formData.fax;
    dati0.www = formData.www;
    dati0.email = formData.email;
    dati0.pec = formData.pec;
    dati0.cm_01 = cm1;
    dati0.cm_02 = cm2;
    dati0.prodotti_esposti = formData.prodotti_esposti;
    console.log("dati0=");
    console.log(dati0);

    console.log("part.datiC=");
console.log(part.datiC);
part.datiC.push(dati0);
console.log(part.datiC);

    //altre aziende
    listDatiC.map((azienda)=>{
      
      console.log(formData.ragione_sociale_+azienda.ordine);
      let dati;

      dati= part.datiC.filter(d => d.espositore_azienda == 1 && 
        (d.cf===formData.cf_+azienda.ordine || d.piva===formData.piva_+azienda.ordine))[0];
        if (dati == undefined) {
        
          dati = {
            idCatalogo: 0,
            id_partecipazione: part.idPartecipazione,
            espositore_azienda: 1,
            ragione_sociale: '',
            indirizzo: '', civico: '', cap: '', localita: '', indirizzo2: '', provincia: '',
            piva: '', cf: '', telefono: '', fax: '', www: '', email: '', pec: '',
            cm_code_01: '', cm_desc_01: '', cm_code_02: '', cm_desc_02: '',
            prodotti_esposti: '',
          }
          

        }
    
        let cm1 = null, cm2 = null;
        let c1 = lista_codici_m.find(c => c.codice === formData.codice_merceologico_01_+azienda.ordine);
        if (c1 != null) cm1 = c1.codice;
        let c2 = lista_codici_m.find(c => c.codice === formData.codice_merceologico_02_+azienda.ordine);
        if (c2 != null) cm2 = c2.codice;
    
    
    
    
        dati.ragione_sociale = formData.ragione_sociale_+azienda.ordine;
        dati.indirizzo = formData.indirizzo_+azienda.ordine;
        dati.civico = formData.civico_+azienda.ordine;
        dati.cap = formData.cap_+azienda.ordine;
        dati.localita = formData.localita_+azienda.ordine;
        dati.indirizzo2 = formData.indirizzo2_+azienda.ordine;
        dati.provincia = formData.provincia_+azienda.ordine;
        dati.piva = formData.piva_+azienda.ordine;
        dati.cf = formData.cf_+azienda.ordine;
        dati.telefono = formData.telefono_+azienda.ordine;
        dati.fax = formData.fax_+azienda.ordine;
        dati.www = formData.www_+azienda.ordine;
        dati.email = formData.email_+azienda.ordine;
        dati.pec = formData.pec_+azienda.ordine;
        dati.cm_01 = cm1;
        dati.cm_02 = cm2;
        dati.prodotti_esposti = formData.prodotti_esposti_+azienda.ordine;
        part.datiC.push(dati);


    });



    var data = {
      idPartecipazione: part.idPartecipazione,
      id_utente: AuthService.getCurrentUser().id,
      manifestazione: part.manifestazione,
      id_expo_richiesta: part.id_expo_richiesta,
      record_catalogo:part.datiC

    };


      ExpoPartecipazioniService.updateDC(part.idPartecipazione,data)
        .then(response => {
          handleResponse(response, "UPD");
        })
        .catch(errore => {
          handleError(errore);
        });
    


  }
  function handleResponse(response, operazione) {
    console.log(response.data);



    setSubmitted(true);

  }

  function handleError(error) {
    setError(error);
    console.log("ERRORE" + error);
    console.log(error.code);

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log("ERROR:RESPONSE" + error.response.data);
      setErrorMessage(error.response.data);

    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("EEROR:REQUEST" + error.request);
      setErrorMessage(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
      setErrorMessage(error.message);
    }

  }

  /* ---------------- ALERT -------------------------------------------------------------------------------------------------------- */

  const [open, setOpen] = useState(false);
  const [openExistent, setOpenExistent] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [confirmExistent, setConfirmExistent] = useState(false);




  function handleClose() {
    setOpen(false);
  }

  function handleConfirm() {
    setConfirm(true);
    setOpen(false);
    deleteObject();
  }



  function handleCloseExistent() {
    setOpenExistent(false);
  }

  function handleConfirmExistent() {
    setConfirmExistent(true);
    setOpenExistent(false);

  }

  const deleteObject = () => {
    setError(null);

    // if (modo === 'edit') {

    //   console.log("delete ");

    //   ERService.remove(prop.idRichiesta)
    //     .then(response => {
    //       console.log("handleResponse");
    //       handleResponse(response, 'DEL');
    //     })
    //     .catch(errore => {
    //       console.log("handleError");
    //       handleError(errore);
    //     });

    // }
  };



  /* ------------------------------navigazione -----------------------------------------------------------------*/


  const annulla = () => {


    navigate("/dati_catalogo");
  }

  const handleKeyDown = (e) => {
    const { key, target } = e

    if (key !== "Enter" || target instanceof HTMLTextAreaElement) {
      return
    }

    e.preventDefault()
  }

  return (
    <DashboardLayout>

      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
          <DialogTitle id="alert-dialog-title">
            Eliminare questo record?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Sei sicuro di voler eliminare questa proposta di partecipazione?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleClose}>No</MDButton>
            <MDButton onClick={handleConfirm} autoFocus>
              SI
            </MDButton>
          </DialogActions>
        </Dialog>

        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card >
              <HeaderForm titolo={"Dati per il catalogo " + manifestazione} save={handleSubmit(inserisci_pp)} exit={annulla} modo='edit' />
              {error !== null &&
                <Alert color="error"><ErrorOutlineOutlinedIcon fontSize="small" />&nbsp;{errorMessage}</Alert>
              }


              <MDBox pt={1} pb={3} px={3} component="form" role="form" onKeyDown={handleKeyDown}>


                <CardContent>
                  <MDBox>
                    <MDBox opacity={1} textAlign="center">

                    </MDBox>
                    <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center" >

                      {(submitted) &&

                        <Alert severity="success">Dati salvati con successo!</Alert>}
                      {error !== null &&
                        <Alert color="error"><ErrorOutlineOutlinedIcon fontSize="small" />&nbsp;{errorMessage}</Alert>
                      }
                    </MDBox>



                    <MDBox mb={4}>

                      <MDButton variant="gradient" color="success" fullWidth>
                        <MDTypography variant="h5" fontWeight="regular" sx={{
                          color: "#FFFFFF"
                        }}>DATI ESPOSITORE</MDTypography>
                      </MDButton>
                    </MDBox>

                    <MDBox mb={2} >
                      <MDBox>
                        <MDInput type="text" label="Ragione Sociale" name='ragione_sociale' fullWidth required {...register('ragione_sociale')}
                          error={errors.ragione_sociale ? true : false} InputLabelProps={{ shrink: true }}
                        />
                        {errors.ragione_sociale ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.ragione_sociale?.message}</span> : ''}
                      </MDBox>
                    </MDBox>
                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                      <MDBox>
                        <MDInput type="text" label="Codice Fiscale" name='cf' fullWidth required {...register('cf')}
                          error={errors.cf ? true : false} InputLabelProps={{ shrink: true }}
                        />
                        {errors.cf ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.cf?.message}</span> : ''}
                      </MDBox>
                      <MDBox>
                        <MDInput type="text" label="Partita IVA" name='piva' fullWidth required {...register('piva')}
                          error={errors.piva ? true : false} InputLabelProps={{ shrink: true }}
                        />
                        {errors.piva ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.piva?.message}</span> : ''}
                      </MDBox>
                    </MDBox>

                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '3fr 1fr' }}>
                      <MDBox >
                        <MDInput type="text" fullWidth label="Indirizzo" required name='indirizzo'  {...register('indirizzo')} error={errors.indirizzo ? true : false} InputLabelProps={{ shrink: true }} />
                        {errors.indirizzo ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.indirizzo?.message}</span> : ''}
                      </MDBox>
                      <MDBox>
                        <MDInput type="text" fullWidth label="civico" required name='civico'  {...register('civico')} error={errors.civico ? true : false} InputLabelProps={{ shrink: true }} />
                        {errors.civico ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.civico?.message}</span> : ''}
                      </MDBox>

                    </MDBox>
                    <MDBox mb={2} >
                      <MDInput type="text" label="Dettaglio Indirizzo (frazione, c/o, piano, ...)" fullWidth name='indirizzo2' {...register('indirizzo2')} error={errors.indirizzo2 ? true : false} InputLabelProps={{ shrink: true }} />
                      {errors.indirizzo2 ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.indirizzo2?.message}</span> : ''}
                    </MDBox>

                    <Grid container spacing={2} mb={2}>
                      <Grid item xs={4} md={2} >
                        <MDBox>
                          <MDInput type="text" label="CAP" fullWidth name='cap' required
                            {...register('cap', {
                              onChange: (e) => setQuery_cap(e.target.value),

                            })} error={errors.cap ? true : false} InputLabelProps={{ shrink: true }}
                          />
                          {errors.cap ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.cap?.message}</span> : ''}
                        </MDBox>
                      </Grid>

                      <Grid item xs={8} md={8} >
                        <MDBox>
                          <Autocomplete
                            freeSolo
                            value={part.localita}
                            options={lista_loc.map((loc) => loc.localita)}
                            renderInput={(params) =>
                              <MDInput type="text" {...params} label="località" name='localita' fullWidth
                                {...register('localita')}
                                InputLabelProps={{ shrink: true }} />}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={4} md={2} >
                        <MDBox>
                          <MDInput type="text" label="provincia" name='provincia' fullWidth required  {...register('provincia')} error={errors.provincia ? true : false} InputLabelProps={{ shrink: true }} />
                          {errors.provincia ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.provincia?.message}</span> : ''}
                        </MDBox>
                      </Grid></Grid>

                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                      <MDBox>
                        <MDInput type="text" label="Telefono" name='telefono' fullWidth required {...register('telefono')}
                          error={errors.telefono ? true : false} InputLabelProps={{ shrink: true }}
                        />
                        {errors.telefono ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.telefono?.message}</span> : ''}
                      </MDBox>
                      <MDBox>
                        <MDInput type="text" label="Fax" name='fax' fullWidth {...register('fax')} InputLabelProps={{ shrink: true }} />
                      </MDBox>
                    </MDBox>

                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                      <MDBox>
                        <MDInput type="text" label="WWW" name='www' fullWidth  {...register('www')}
                          error={errors.www ? true : false} InputLabelProps={{ shrink: true }}
                        />
                        {errors.www ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.www?.message}</span> : ''}
                      </MDBox>
                      <MDBox mb={2} >
                        <MDInput type="text" label="Email" fullWidth required name='email' {...register('email')} error={errors.email ? true : false} InputLabelProps={{ shrink: true }} />
                        {errors.email ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.email?.message}</span> : ''}
                      </MDBox>
                    </MDBox>

                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                      <MDBox>
                        <MDInput type="text" label="PEC" name='pec' fullWidth required {...register('pec')}
                          error={errors.pec ? true : false} InputLabelProps={{ shrink: true }}
                        />
                        {errors.pec ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.pec?.message}</span> : ''}
                      </MDBox>
                      <MDBox mb={2} >
                      </MDBox>
                    </MDBox>

                    <MDBox mb={2}>
                      <MDTypography variant="button" fontWeight="regular" color="text"> Codici Merceologici</MDTypography>
                    </MDBox>
                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                      <MDBox>
                        <ReactHookFormSelect
                          id="codice_merceologico_01"
                          name="codice_merceologico_01"
                          label="Codice Merceologico 1"
                          control={control}
                          defaultValue=''
                          variant="outlined"
                          required
                          error={errors.codice_merceologico_01 ? true : false}
                        >
                          {lista_codici_m.map((cm) => (
                            <MenuItem
                              key={cm.codice}
                              value={cm.codice}
                            >
                              {cm.codice} - {cm.descrizione}
                            </MenuItem>
                          ))}

                        </ReactHookFormSelect>
                        {errors.codice_merceologico_01 ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.codice_merceologico_01?.message}</span> : ''}
                      </MDBox>
                      <MDBox>
                        <ReactHookFormSelect
                          id="codice_merceologico_02"
                          name="codice_merceologico_02"
                          label="Codice Merceologico 2"
                          control={control}
                          defaultValue=''
                          variant="outlined"
                        >
                          {lista_codici_m.map((cm) => (
                            <MenuItem
                              key={cm.codice}
                              value={cm.codice}
                            >
                              {cm.codice} - {cm.descrizione}
                            </MenuItem>
                          ))}

                        </ReactHookFormSelect>
                      </MDBox>
                    </MDBox>



                    <MDBox mb={2}>
                      <MDBox>
                        <MDInput type="text" label="Prodotti / Servizi in esposizione " fullWidth required
                          name='prodotti_esposti' {...register('prodotti_esposti')} multiline rows={3} InputLabelProps={{ shrink: true }}
                          error={errors.prodotti_esposti ? true : false} />
                        {errors.prodotti_esposti ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.prodotti_esposti?.message}</span> : ''}
                      </MDBox>
                    </MDBox>


                    <MDBox mb={4}>

                      <MDButton variant="gradient" color="success" fullWidth>
                        <MDTypography variant="h5" fontWeight="regular" sx={{
                          color: "#FFFFFF"
                        }}>DATI AZIENDE RAPPRESENTATE</MDTypography>
                      </MDButton>
                    </MDBox>

                    {listDatiC.map((azienda) => (
                      <Card>
                        <CardContent>

                          <MDBox mb={2} >
                            <MDBox>
                              <MDInput type="text" label="Azienda" name={'ragione_sociale_' + azienda.ordine} fullWidth required {...register('ragione_sociale_' + azienda.ordine)}
                                error={errors.ragione_sociale + azienda.ordine ? true : false} InputLabelProps={{ shrink: true }}
                              />
                              {errors.ragione_sociale + azienda.ordine ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.ragione_sociale + azienda.ordine?.message}</span> : ''}
                            </MDBox>
                          </MDBox>
                          <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                            <MDBox>
                              <MDInput type="text" label="Codice Fiscale" name={'cf_' + azienda.ordine} fullWidth required {...register('cf_' + azienda.ordine)}
                                error={errors.cf_ + azienda.ordine ? true : false} InputLabelProps={{ shrink: true }}
                              />
                              {errors.cf_ + azienda.ordine ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.cf_ + azienda.ordine?.message}</span> : ''}
                            </MDBox>
                            <MDBox>
                              <MDInput type="text" label="Partita IVA" name={'piva_' + azienda.ordine} fullWidth required {...register('piva_' + azienda.ordine)}
                                error={errors.piva_ + azienda.ordine ? true : false} InputLabelProps={{ shrink: true }}
                              />
                              {errors.piva_ +azienda.ordine ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.piva_ + azienda.ordine?.message}</span> : ''}
                            </MDBox>
                          </MDBox>


                          <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '3fr 1fr' }}>
                    <MDBox >
                      <MDInput type="text" fullWidth label="Indirizzo" required name={'indirizzo_'+azienda.ordine}  {...register('indirizzo_'+azienda.ordine)} 
                      error={errors.indirizzo_+azienda.ordine ? true : false} InputLabelProps={{ shrink: true }} />
                      {errors.indirizzo_+azienda.ordine ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.indirizzo_+azienda.ordine?.message}</span> : ''}
                    </MDBox>
                    <MDBox>
                      <MDInput type="text" fullWidth label="civico" required name={'civico_'+azienda.ordine}  {...register('civico_'+azienda.ordine)}
                      error={errors.civico_+azienda.ordine ? true : false} InputLabelProps={{ shrink: true }} />
                      {errors.civico_+azienda.ordine ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.civico_+azienda.ordine?.message}</span> : ''}
                    </MDBox>

                  </MDBox>
                  <MDBox mb={2} >
                    <MDInput type="text" label="Dettaglio Indirizzo (frazione, c/o, piano, ...)" fullWidth name={'indirizzo2_'+azienda.ordine} 
                    {...register('indirizzo2_'+azienda.ordine)} error={errors.indirizzo2_+azienda.ordine ? true : false} InputLabelProps={{ shrink: true }} />
                    {errors.indirizzo2_+azienda.ordine ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.indirizzo2_+azienda.ordine?.message}</span> : ''}
                  </MDBox>

                  <Grid container spacing={2} mb={2}>
                    <Grid item xs={4} md={2} >
                      <MDBox>
                        <MDInput type="text" label="CAP" fullWidth name={'cap_'+azienda.ordine} required
                          {...register('cap_'+azienda.ordine, {
                            onChange: (e) => setQuery_cap(e.target.value),

                          })} error={errors.cap_+azienda.ordine ? true : false} InputLabelProps={{ shrink: true }}
                        />
                        {errors.cap_+azienda.ordine ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.cap_+azienda.ordine?.message}</span> : ''}
                      </MDBox>
                    </Grid>

                    <Grid item xs={8} md={8} >
                      <MDBox>
                        <Autocomplete
                          freeSolo
                          value={azienda.localita}
                          options={lista_loc.map((loc) => loc.localita)}
                          renderInput={(params) =>
                            <MDInput type="text" {...params} label="località" name={'localita_'+azienda.ordine} fullWidth
                              {...register('localita_'+azienda.ordine)}
                              InputLabelProps={{ shrink: true }} />}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={4} md={2} >
                      <MDBox>
                        <MDInput type="text" label="provincia" name={'provincia_'+azienda.ordine} fullWidth required  {...register('provincia_'+azienda.ordine)}
                         error={errors.provincia_+azienda.ordine ? true : false} InputLabelProps={{ shrink: true }} />
                        {errors.provincia_+azienda.ordine ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.provincia_+azienda.ordine?.message}</span> : ''}
                      </MDBox>
                    </Grid></Grid>

                  <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                    <MDBox>
                      <MDInput type="text" label="Telefono" name={'telefono_'+azienda.ordine} fullWidth required {...register('telefono_'+azienda.ordine)}
                        error={errors.telefono_+azienda.ordine ? true : false} InputLabelProps={{ shrink: true }}
                      />
                      {errors.telefono_+azienda.ordine ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.telefono_+azienda.ordine?.message}</span> : ''}
                    </MDBox>
                    <MDBox>
                      <MDInput type="text" label="Fax" name={'fax_'+azienda.ordine} fullWidth {...register('fax_'+azienda.ordine)} 
                      InputLabelProps={{ shrink: true }} />
                    </MDBox>
                  </MDBox>

                  <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                    <MDBox>
                      <MDInput type="text" label="WWW" name={'www_'+azienda.ordine} fullWidth  {...register('www_'+azienda.ordine)}
                        error={errors.www_+azienda.ordine ? true : false} InputLabelProps={{ shrink: true }}
                      />
                      {errors.www_+azienda.ordine ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.www_+azienda.ordine?.message}</span> : ''}
                    </MDBox>
                    <MDBox mb={2} >
                      <MDInput type="text" label="Email" fullWidth required name={'email_'+azienda.ordine} {...register('email_'+azienda.ordine)} 
                      error={errors.email_+azienda.ordine ? true : false} InputLabelProps={{ shrink: true }} />
                      {errors.email_+azienda.ordine ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.email_+azienda.ordine?.message}</span> : ''}
                    </MDBox>
                  </MDBox>

                  <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                    <MDBox>
                      <MDInput type="text" label="PEC" name={'pec_'+azienda.ordine} fullWidth required {...register('pec_'+azienda.ordine)}
                        error={errors.pec_+azienda.ordine ? true : false} InputLabelProps={{ shrink: true }}
                      />
                      {errors.pec_+azienda.ordine ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.pec_+azienda.ordine?.message}</span> : ''}
                    </MDBox>
                    <MDBox mb={2} >
                    </MDBox>
                  </MDBox>

                  <MDBox mb={2}>
                    <MDTypography variant="button" fontWeight="regular" color="text"> Codici Merceologici</MDTypography>
                  </MDBox>
                  <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                    <MDBox>
                      <ReactHookFormSelect
                        id={"codice_merceologico_01_"+azienda.ordine}
                        name={"codice_merceologico_01_"+azienda.ordine}
                        label="Codice Merceologico 1"
                        control={control}
                        defaultValue=''
                        variant="outlined"
                        required
                        error={errors.codice_merceologico_01_+azienda.ordine ? true : false}
                      >
                        {lista_codici_m.map((cm) => (
                          <MenuItem
                            key={cm.codice}
                            value={cm.codice}
                          >
                            {cm.codice} - {cm.descrizione}
                          </MenuItem>
                        ))}

                      </ReactHookFormSelect>
                      {errors.codice_merceologico_01_+azienda.ordine ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.codice_merceologico_01_+azienda.ordine?.message}</span> : ''}
                    </MDBox>
                    <MDBox>
                      <ReactHookFormSelect
                        id={"codice_merceologico_02_"+azienda.ordine}
                        name={"codice_merceologico_02_"+azienda.ordine}
                        label="Codice Merceologico 2"
                        control={control}
                        defaultValue=''
                        variant="outlined"
                      >
                        {lista_codici_m.map((cm) => (
                          <MenuItem
                            key={cm.codice}
                            value={cm.codice}
                          >
                            {cm.codice} - {cm.descrizione}
                          </MenuItem>
                        ))}

                      </ReactHookFormSelect>
                    </MDBox>
                  </MDBox>



                  <MDBox mb={2}>
                    <MDBox>
                      <MDInput type="text" label="Prodotti / Servizi in esposizione " fullWidth required
                        name={'prodotti_esposti_'+azienda.ordine} {...register('pprodotti_esposti_'+azienda.ordine)} multiline rows={3} InputLabelProps={{ shrink: true }}
                        error={errors.prodotti_esposti_+azienda.ordine ? true : false} />
                      {errors.prodotti_esposti_+azienda.ordine ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.prodotti_esposti_+azienda.ordine?.message}</span> : ''}
                    </MDBox>
                  </MDBox>


                        </CardContent>
                      </Card>
                    ))}
 <MDBox sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} mt={4} mb={1}>
                
<MDBox sx={{ flex: '1 1 auto' }} />
                      <MDBox ml={2}>

                      </MDBox>
                      <MDBox ml={2}>
                        <MDButton variant="gradient" color="success" onClick={aggiungi_azienda} type="submit"  >
                          + Aggiungi Azienda
                        </MDButton>
                      </MDBox>

           </MDBox>


                    <MDBox mb={2}>
                    </MDBox>



                    <MDBox sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} mt={4} mb={1}>
                      <MDButton variant="outlined" color="success" onClick={annulla} >
                        Annulla le modifiche
                      </MDButton>
                      <MDBox sx={{ flex: '1 1 auto' }} />
                      <MDBox ml={2}>

                      </MDBox>
                      <MDBox ml={2}>
                        <MDButton variant="gradient" color="success" onClick={handleSubmit(inserisci_pp)} type="submit"  >
                          Salva
                        </MDButton>
                      </MDBox>

                    </MDBox>


                    <MDBox>

                    </MDBox>


                  </MDBox>
                </CardContent>

              </MDBox>

            </Card>
          </Grid >
        </Grid >
      </MDBox >
      <Footer />
    </DashboardLayout >
  );
}

export default DatiCatalogoEdit;
