import { jwtDecode } from "jwt-decode";

export default function authHeader() {


  const user = JSON.parse(localStorage.getItem('user'));
  

  if (user ) {
   

    return {  'Content-Type': 'application/json' ,Authorization: 'Bearer ' + user.accessToken , 'Access-Control-Allow-Origin': '*',     };

  }else return{}

  }

