import { Card } from "@mui/material";
import MDBox from "common/components/MD/MDBox";
import MDInput from "common/components/MD/MDInput";
import MDTypography from "common/components/MD/MDTypography";
//script
import currencyFormat from "common/script/currencyFormat";
function SpazioComponent({ id, name, richiesta }) {

    return ( 
<Card mb={2}>
              <MDBox>
                     <MDBox mb={2} ><MDTypography color='success'>Tipologia Espositiva Assegnata</MDTypography></MDBox>

                                                            <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr 2fr' }}>
                                                                <MDBox>
                                                                    <MDInput type="text" label="Tipologia Espositiva" name='tipologia' fullWidth disabled value={richiesta.tipologia_concessa} />
                                                                </MDBox>
                                                                <MDBox>
                                                                    <MDInput type="text" label="Settore Merceologico" name='settore' fullWidth disabled value={richiesta.settore_concesso} />
                                                                </MDBox>
                                                                <MDBox>
                                                                <MDInput type="text" label="Mq Assegnati" name='mq' fullWidth disabled value={richiesta.mq_concessi} />
                                                      
                                                                </MDBox>
                                                              
                                                       
        
                                                            </MDBox>
                                                            <MDBox mb={2} >
                                                                    <MDInput type="text" label="Dettaglio" name='dettaglio' fullWidth disabled value={richiesta.dettaglio} />
                                                                </MDBox>
                                                            <MDBox mb={2} >
                                                                <MDInput type="text" label="Note " multiline rows={5} fullWidth variant="outlined" disabled value={richiesta.note_accettazione} />
                                                            </MDBox>
                                                           <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr 1fr 1fr ' }}>

                                                             <MDBox></MDBox>
                                                                                                                <MDBox></MDBox>
                                                                                                                <MDBox></MDBox>
                                                                                                               
                                                                                                                <MDInput type="text" label="Prezzo" name='prezzo' fullWidth disabled value={currencyFormat(richiesta.prezzo)} />
                                                            
                                                           </MDBox>
        
        
                                                        </MDBox>
                                                        </Card>
     );

}
export default SpazioComponent;
