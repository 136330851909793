/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, forwardRef } from "react";
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';

// react-router-dom components
import { Link, useLocation, useNavigate } from "react-router-dom";

import AuthService from "services/API/Auth/AuthService";
import ComuniService from 'services/API/Comuni/ComuniService.js';
import ERService from 'services/API/ExpoRichieste/ExpoRichiesteService.js';
import UtilityService from "services/API/utility/UtilityService";

// @mui material components
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Grid from "@mui/material/Grid";

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import { pink } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ReactHookFormCheckBox from "common/components/mycomponents/ReactHookFormCheckBox";
import ReactHookFormSelect from "common/components/mycomponents/ReactHookFormSelect";
import DashboardLayout from "layouts/DashboardLayout";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";


// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";
import MDInput from "common/components/MD/MDInput";
import MDTypography from "common/components/MD/MDTypography";



// Authentication layout components piu largo

// Images

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

//validazione lato client
import { useForm } from 'react-hook-form';

import ExpoPartecipazioniService from "services/API/expo_partecipazioni/ExpoPartecipazioniService";
import { useManifestazione } from "common/context/GlobalContext";
import DataTable from "common/components/implemented/Tables/DataTable/DataTables";
import DatiCatalogoEditModal from "./DatiCatalogoEditModal";
import { Icon } from "@mui/material";

function DatiCatalogoVis() {


  const { manifestazione } = useManifestazione();
  const [part, setPart] = useState({
    idPartecipazione: 0,
    id_expo_richiesta: 0,
    richiesta0:{ragione_sociale:'',indirizzo:'',cap:'',localita:'',procincia:''},
    datiC: [{}]
  });
  const [id_cat, setId_cat] = useState(0);
  const [datiE, setDatiE] = useState({
    idCatalogo: 0, ragione_sociale: '', piva: '', cf: '', indirizzo: '', civico: '',
    cap: '', localita: '', indirizzo2: '', provincia: '', telefono: '', fax: '', www: '', email: '', pec: '',
    cm_01: '', cm_02: '', prodotti_esposti: '',inviato:false
  });

  // user state for form
  const [listDatiC, setListDatiC] = useState([{
    idCatalogo: 0,
    id_partecipazione: 0,
    espositore_azienda: 0,
    ragione_sociale: '',
    indirizzo: '', civico: '', cap: '', localita: '', indirizzo2: '', provincia: '',
    piva: '', cf: '', telefono: '', fax: '', www: '', email: '', pec: '',
    cm_code_01: '', cm_desc_01: '', cm_code_02: '', cm_desc_02: '',
    prodotti_esposti: '', ordine: 0,inviato:false
  }]);

  const [lista_loc, setLista_loc] = useState([
    { cap: '', localita: '', sigla_provincia: '', denominazione_provincia: '' },
  ]);
  const [lista_codici_m, setLista_codici_m] = useState([
    { codice: '', descrizione: '' },
  ]);


  useEffect(() => {
    console.log("carico i codici merceologici vis");

    async function fetchInitialData2() {
      const response = await UtilityService.getAllCodiciM();
      response.errors ? console.log(response.errors) : setLista_codici_m([...response.data]);
    }
    fetchInitialData2();

  }, []);




  /* ----------------------------FUNZIONALITA PAGINA------------------------------------------------- */
  /*const [query_cap, setQuery_cap] = useState('');
  const debouncedQuery = useDebounce(query_cap, 500); // Debounce di 500ms


  // Effettua la ricerca quando `debouncedQuery` cambia
  useEffect(() => {
    if (debouncedQuery) {
      console.log(`Effettua la ricerca per: ${debouncedQuery}`);
      // Qui potresti invocare un'API di ricerca o filtrare i dati localmente
      //  console.log("retrive");
      setLista_loc([
        { cap: '', localita: '', sigla_provincia: '', denominazione_provincia: '' },
      ]);
      // setValue('localita', '');

      ComuniService.getAllComuni(query_cap)
        .then(response => {
          setLista_loc([...response.data]);
          //   console.log(response.status);
          if (response.status === 200) {
            console.log(response.data[0].localita);
            /* setValue('provincia', response.data[0].sigla_provincia.toUpperCase());
        
       
          }


        })
        .catch(e => {
          console.log(e);
        });
    }
  }, [debouncedQuery]);

*/
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const aggiungi_espositore = () => {
    console.log(`id catalogo espositore:` + datiE.idCatalogo);
    setId_cat(datiE.idCatalogo);
    handleShow2();
  }


  const modifica_espositore = () => {
    console.log(`id catalogo espositore:` + datiE.idCatalogo);
    setId_cat(datiE.idCatalogo);
    handleShow2();
  }

  const salva_espositore = (formData) => {
    if (AuthService.isTokenOk()) {
    console.log("SALVO DATI ESPOSITORE");

    setError(null);


    let cm1 = null, cm2 = null;
    let c1 = lista_codici_m.find(c => c.codice === formData.codice_merceologico_01);
    if (c1 != null) cm1 = c1.codice;
    let c2 = lista_codici_m.find(c => c.codice === formData.codice_merceologico_02);
    if (c2 != null) cm2 = c2.codice;

    let dati0;

    dati0 = part.datiC.filter(d => d.espositore_azienda == 0)[0];


    //console.log("dati0 prima delle modifiche=");
    //console.log(dati0);

    if (dati0 == undefined) {

      dati0 = {
        idCatalogo: 0,
        id_partecipazione: part.idPartecipazione,
        espositore_azienda: 0,
        ragione_sociale: '',
        indirizzo: '', civico: '', cap: '', localita: '', indirizzo2: '', provincia: '',
        piva: '', cf: '', telefono: '', fax: '', www: '', email: '', pec: '',
        cm_code_01: '', cm_desc_01: '', cm_code_02: '', cm_desc_02: '',
        prodotti_esposti: '',
      }

      //part.datiC.push(dati0);
    }


    dati0.ragione_sociale = formData.ragione_sociale;
    dati0.indirizzo = formData.indirizzo;
    dati0.civico = formData.civico;
    dati0.cap = formData.cap;
    dati0.localita = formData.localita;
    dati0.indirizzo2 = formData.indirizzo2;
    dati0.provincia = formData.provincia;
    dati0.piva = formData.piva;
    dati0.cf = formData.cf;
    dati0.telefono = formData.telefono;
    dati0.fax = formData.fax;
    dati0.www = formData.www;
    dati0.email = formData.email;
    dati0.pec = formData.pec;
    dati0.cm_01 = cm1;
    dati0.cm_02 = cm2;
    dati0.prodotti_esposti = formData.prodotti_esposti;
   // console.log("dati0=");
   // console.log(dati0);

   // console.log("part.datiC=");
   // console.log(part.datiC);
    part.datiC.push(dati0);
  //  console.log(part.datiC);

    var data = {
      idPartecipazione: part.idPartecipazione,
      id_utente: AuthService.getCurrentUser().id,
      manifestazione: part.manifestazione,
      id_expo_richiesta: part.id_expo_richiesta,
      record_catalogo: part.datiC

    };


    ExpoPartecipazioniService.updateDC(part.idPartecipazione, data)
      .then(response => {
        handleResponse(response, "UPD");
      })
      .catch(errore => {
        handleError(errore);
      });

    }
  };


  const invia_espositore = () => {
    console.log("INVIO DATI ESPOSITORE");
    let dati0= part.datiC.filter(d => d.espositore_azienda == 0)[0];
  
    if (dati0 != undefined) {
      //controllo
      if(dati0.ragione_sociale !='' &&
      dati0.indirizzo != '' &&
      dati0.civico != '' &&
      dati0.cap !='' &&
      dati0.localita!= '' &&
      dati0.provincia !='' &&
      dati0.telefono != '' &&
      dati0.email !='' && 
      dati0.cm_01 !='' &&
      dati0.prodotti_esposti != ''){
        ExpoPartecipazioniService.inviaDC(dati0.idCatalogo)
        .then(response => {
          handleResponse(response, "SENT");
        })
        .catch(errore => {
          handleError(errore);
        });
  

      }else{

        alert("Non sono stati compilati tutti i campi necessari");
      }
    }else{

      alert("Dati non trovati");
    }


  }


  const invia = (idCatalogo) => {

    if (AuthService.isTokenOk()) {
    console.log("INVIO DATI AZIENDA ="+idCatalogo);
    let dati0= part.datiC.filter(d => (d.espositore_azienda == 1 && d.idCatalogo==idCatalogo))[0];
    console.log(dati0);
    if (dati0 != undefined) {
      //controllo
      if(dati0.ragione_sociale !='' &&
      dati0.indirizzo != '' &&
      dati0.civico != '' &&
      dati0.cap !='' &&
      dati0.localita!= '' &&
      dati0.provincia !='' &&
      dati0.telefono != '' &&
      dati0.email !='' && 
      dati0.cm_01 !='' &&
      dati0.prodotti_esposti != ''){
        ExpoPartecipazioniService.inviaDC(dati0.idCatalogo)
        .then(response => {
          handleResponse(response, "SENT");
        })
        .catch(errore => {
          handleError(errore);
        });
  

      }else{

        alert("Non sono stati compilati tutti i campi necessari");
      }
    }else{

      alert("Dati non trovati");
    }
  }

  }


  const aggiungi_azienda = () => {
    /* const size =listDatiC.length;
   
     const newAzienda = {   idCatalogo: 0,
       id_partecipazione: part.idPartecipazione,
       espositore_azienda: 1,
       ragione_sociale: '',
       indirizzo: '', civico: '', cap: '', localita: '', indirizzo2: '', provincia: '',
       piva: '', cf: '', telefono: '', fax: '', www: '', email: '', pec: '',
       cm_code_01: '', cm_desc_01: '', cm_code_02: '', cm_desc_02: '',
       prodotti_esposti: '', ordine: size };
   
     // Aggiornare lo stato immutabilmente
     setListDatiC(prev => [...prev, newAzienda]);*/
     setId_cat(0);
    handleShow3();
  }

  const modifica_azienda = (idc) => {

    setId_cat(idc);
    handleShow3();
  }


  const salva_azienda = (formData) => {
    if (AuthService.isTokenOk()) {
    setError(null);


    let cm1 = null, cm2 = null;
    let c1 = lista_codici_m.find(c => c.codice === formData.codice_merceologico_01);
    if (c1 != null) cm1 = c1.codice;
    let c2 = lista_codici_m.find(c => c.codice === formData.codice_merceologico_02);
    if (c2 != null) cm2 = c2.codice;

    let dati0;

    if(id_cat===0){
      const size =listDatiC.length;
      dati0 = {
        idCatalogo: 0,
        id_partecipazione: part.idPartecipazione,
        espositore_azienda: 1,
        ragione_sociale: '',
        indirizzo: '', civico: '', cap: '', localita: '', indirizzo2: '', provincia: '',
        piva: '', cf: '', telefono: '', fax: '', www: '', email: '', pec: '',
        cm_code_01: '', cm_desc_01: '', cm_code_02: '', cm_desc_02: '',
        prodotti_esposti: '',ordine:size,inviato:false
      }
      part.datiC.push(dati0);
    }
    
    else{
   

    dati0 = part.datiC.filter(d => d.espositore_azienda === 1 && d.idCatalogo===id_cat)[0];
    }

    console.log("dati0 prima delle modifiche=");
    console.log(dati0);

    dati0.ragione_sociale = formData.ragione_sociale;
    dati0.indirizzo = formData.indirizzo;
    dati0.civico = formData.civico;
    dati0.cap = formData.cap;
    dati0.localita = formData.localita;
    dati0.indirizzo2 = formData.indirizzo2;
    dati0.provincia = formData.provincia;
    dati0.piva = formData.piva;
    dati0.cf = formData.cf;
    dati0.telefono = formData.telefono;
    dati0.fax = formData.fax;
    dati0.www = formData.www;
    dati0.email = formData.email;
    dati0.pec = formData.pec;
    dati0.cm_01 = cm1;
    dati0.cm_02 = cm2;
    dati0.prodotti_esposti = formData.prodotti_esposti;
    console.log("dati0=");
    console.log(dati0);

    console.log("part.datiC=");
    console.log(part.datiC);
   

    var data = {
      idPartecipazione: part.idPartecipazione,
      id_utente: AuthService.getCurrentUser().id,
      manifestazione: part.manifestazione,
      id_expo_richiesta: part.id_expo_richiesta,
      record_catalogo: part.datiC

    };


    ExpoPartecipazioniService.updateDC(part.idPartecipazione, data)
      .then(response => {
        handleResponse(response, "UPD");
      })
      .catch(errore => {
        handleError(errore);
      });

    }

  }




  /* ----------------------------CARICAMENTO E RIEMPIMENTO CAMPI------------------------------------------------- */



  useEffect(() => {
    if (AuthService.isTokenOk()) {
    seleziona_soggetto();
    }
  }, []);

  async function seleziona_soggetto() {
    //console.log("Cambiata pp->seleziono i dettagli :"+manifestazione);

    const response = await ExpoPartecipazioniService.seleziona_partecipazione(manifestazione);
    response.errors ? console.log(response.errors) : retrive(response.data);

  }

  function retrive(data) {
    console.log(data);
    //console.log("retrive =" + data.idRichiesta);

    setPart({
      idPartecipazione: data.idPartecipazione,
      id_expo_richiesta: data.id_expo_richiesta,
      id_utente: data.id_utente,
      datiC: data.record_catalogo,
      richiesta0:data.richiesta0
    });

    console.log("selezionata  " + part.idPartecipazione);

  }


  useEffect(() => {

    console.log("Cambiata pp->riempo i campi  " + part.idPartecipazione);

    if (part != null && part.datiC != null && part.datiC.length > 0) {
      console.log("datiC non nulli" );
      const dati0 = part.datiC.filter(d => d.espositore_azienda === 0)[0];
      if (dati0 != null) {
        // reset form with user data
        setDatiE({
          idCatalogo: dati0.idCatalogo,
          id_partecipazione: dati0.id_partecipazione,
          espositore_azienda: dati0.espositore_azienda,
          ragione_sociale: dati0.ragione_sociale,
          indirizzo: dati0.indirizzo,
          civico: dati0.civico,
          cap: dati0.cap, localita: dati0.localita,
          indirizzo2: dati0.indirizzo2, provincia: dati0.provincia,
          piva: dati0.piva, cf: dati0.cf, telefono: dati0.telefono, fax: dati0.fax, www: dati0.www, email: dati0.email, pec: dati0.pec,
          cm_01: dati0.cm_01, cm_02: dati0.cm_02, 
          prodotti_esposti: dati0.prodotti_esposti, ordine: dati0.ordine,inviato:dati0.inviato
        });
        setListDatiC(part.datiC.filter(d => d.espositore_azienda == 1));

      }
    }
  }, [part]);


  const ppAttributes = [];

  if (listDatiC.length > 0) {
    listDatiC.forEach(azienda => {

      let action = (
        <MDBox><MDButton variant="outlined" color={"success"} iconOnly={true} disabled={azienda.inviato} onClick={() =>modifica_azienda(azienda.idCatalogo)}>
          <Icon>edit</Icon>
        </MDButton>
             <MDButton variant="outlined" color={"success"} iconOnly={true} onClick={() =>elimina_azienda(azienda.idCatalogo)} disabled={azienda.inviato} >
              <Icon>delete</Icon>
            </MDButton>
            <MDButton variant="outlined" color={"success"} iconOnly={true} disabled={azienda.inviato} onClick={() =>invia(azienda.idCatalogo)}>
          <Icon>send</Icon>
        </MDButton>
        </MDBox>
      );

      ppAttributes.push({
        azienda: azienda.ragione_sociale,
        action: action,

      })
    });
  }
  const ppTableData = {
    columns: [
      { Header: "Azienda", accessor: "azienda", align: "left" },
      { Header: "Azioni", accessor: "action", align: "center", width: "20%", enableGlobalFilter: false },

    ],

    rows: ppAttributes,


  };

  /* ----------------------------VALIDAZIONE E SALVATAGGIO----------------------------------------------------------------------- */


  /* ----------------------------SALVATAGGIO E REGISTRAZIONE------------------------------------------------- */

  const {
    register, //register inputs
    reset, setValue, control,
    handleSubmit,//handle form submit
    formState: { errors }
  } = useForm({
  });



  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);


  function handleResponse(response, operazione) {
   // console.log(response.data);
    seleziona_soggetto();
    setSubmitted(true);
    handleClose2();
    handleClose3();

  }

  function handleError(error) {
    setError(error);
    console.log("ERRORE" + error);
    console.log(error.code);

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log("ERROR:RESPONSE" + error.response.data);
      console.log( error.response.data);
      setErrorMessage(error.response.data);


    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("EEROR:REQUEST" + error.request);
      setErrorMessage(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
      setErrorMessage(error.message);
    }

    handleClose2();
    handleClose3();
  }

  /* ---------------- ALERT -------------------------------------------------------------------------------------------------------- */

  const [open, setOpen] = useState(false);
  const [openExistent, setOpenExistent] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [confirmExistent, setConfirmExistent] = useState(false);

  const [idc_da_cancellare, setIdc_da_cancellare] = useState(0);

  function elimina_azienda(idc) {
    console.log("elimina azienda con id="+idc);
    setIdc_da_cancellare(idc);
 
    setOpen(true);
  
    
  }



  function handleClose() {
    setOpen(false);
  }

  function handleConfirm() {
    setConfirm(true);
    setOpen(false);
    deleteObject();
  }



  function handleCloseExistent() {
    setOpenExistent(false);
  }

  function handleConfirmExistent() {
    setConfirmExistent(true);
    setOpenExistent(false);

  }

  const deleteObject = () => {

    if (AuthService.isTokenOk()) {
    setError(null);

    console.log("idc_da_cancellare="+idc_da_cancellare);

    let datidc= part.datiC.filter(d => (d.espositore_azienda === 1 && d.idCatalogo===idc_da_cancellare));


    let dati0;
    dati0 = part.datiC.filter(d => (d.espositore_azienda === 1 && d.idCatalogo!=idc_da_cancellare));
    

    console.log("dati0 dopo delle modifiche=");
    console.log(dati0);


    var data = {
      idPartecipazione: part.idPartecipazione,
      id_utente: AuthService.getCurrentUser().id,
      manifestazione: part.manifestazione,
      id_expo_richiesta: part.id_expo_richiesta,
      record_catalogo: dati0,
      record_catalogo_dc:datidc
    };


    ExpoPartecipazioniService.updateDC(part.idPartecipazione, data)
      .then(response => {
        handleResponse(response, "UPD");
      })
      .catch(errore => {
        handleError(errore);
      });

    }
  };



  /* ------------------------------navigazione -----------------------------------------------------------------*/





  return (
    <DashboardLayout>

      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
          <DialogTitle id="alert-dialog-title">
            Eliminare questo record?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Sei sicuro di voler eliminare questa azienda dalle aziende rappresentate?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleClose}>No</MDButton>
            <MDButton onClick={handleConfirm} autoFocus>
              SI
            </MDButton>
          </DialogActions>
        </Dialog>

        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card >
              <HeaderForm titolo={"Dati per il catalogo " + manifestazione} modo='vis' />
              {error !== null &&
                <Alert color="error"><ErrorOutlineOutlinedIcon fontSize="small" />&nbsp;{errorMessage}</Alert>
              }


              <MDBox pt={1} pb={3} px={3} component="form" role="form">


                <CardContent>
                  <MDBox>
                    <MDBox opacity={1} textAlign="center">

                    </MDBox>
                    <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center" >

                      {(submitted) &&

                        <Alert severity="success">Dati salvati con successo!</Alert>}
                      {error !== null &&
                        <Alert color="error"><ErrorOutlineOutlinedIcon fontSize="small" />&nbsp;{errorMessage}</Alert>
                      }
                    </MDBox>

 
                    <MDBox mb={4}>
                    <MDBox mb={2} >
                    <Alert severity="warning">
                      <MDTypography variant="button" fontWeight="regular" >Verificare e/o aggiornare i propri Dati Espositore. </MDTypography>
                      <MDTypography variant="button" fontWeight="regular">Se presenti, compilare la scheda per le Aziende Rappresentate, fino ad un massimo di 10. </MDTypography>
                      </Alert>
                      </MDBox>
                      <MDButton variant="gradient" color="success"  fullWidth>
                        <MDTypography variant="h5" fontWeight="regular" sx={{
                          color: "#FFFFFF"
                        }}>DATI ESPOSITORE</MDTypography>
                      </MDButton>
                    </MDBox>
                    <MDBox mb={2} >
                    <Alert severity="info">
                      <MDTypography variant="button" fontWeight="regular">I Dati per il Catalogo devono essere inseriti ed inviati entro il 09 febbraio 2025.</MDTypography>                  
                      </Alert>
                      </MDBox>
                    <MDBox sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} mt={2} mb={1}>

                      <MDBox sx={{ flex: '1 1 auto' }} />
                      <MDBox ml={2}> </MDBox>
                     
                    </MDBox>
                    {datiE.idCatalogo == 0 &&
                         <MDBox sx={{ display: 'flex', flexDirection: 'row', pt: 0}} mt={0} mb={1}>

                         <MDBox sx={{ flex: '1 1 auto' }} />
                      
                         <MDBox ml={2}>
                           <MDButton variant="outlined" color="dark" onClick={aggiungi_espositore} >
                             + Aggiungi Dati Espositore
                           </MDButton>
                         </MDBox>
 
                       </MDBox>
}

                  {datiE.idCatalogo !== 0 &&
                  
                    <MDBox mb={2}  sx={{ display: 'grid', gap: 1, gridTemplateColumns: '10fr 1fr' }}>
                       <MDInput type="text" label="Ragione Sociale" name='ragione_sociale' fullWidth disabled value={datiE.ragione_sociale} />
                       <MDBox ml={2}  sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                        <MDButton variant="outlined" color="success" onClick={modifica_espositore} disabled={datiE.inviato} >
                          Modifica
                        </MDButton>
                        <MDButton variant="outlined" color="success" onClick={invia_espositore} disabled={datiE.inviato || datiE.idCatalogo==0} >
                          Invia
                        </MDButton>
                      </MDBox>
                    </MDBox>
}
                        {/*
                         <MDBox ml={2}  sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                        <MDButton variant="outlined" color="success" onClick={modifica_espositore} disabled={datiE.inviato} >
                          Modifica
                        </MDButton>
                        <MDButton variant="outlined" color="success" onClick={invia_espositore} disabled={datiE.inviato || datiE.idCatalogo==0} >
                          Invia
                        </MDButton>
                      </MDBox>

                    <MDBox mb={2} >
                      <MDBox>
                        <MDInput type="text" label="Ragione Sociale" name='ragione_sociale' fullWidth disabled value={datiE.ragione_sociale} />
                      </MDBox>
                    </MDBox>
                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                      <MDBox>
                        <MDInput type="text" label="Codice Fiscale" name='cf' fullWidth disabled value={datiE.cf} />
                      </MDBox>
                      <MDBox>
                        <MDInput type="text" label="Partita IVA" name='piva' fullWidth disabled value={datiE.piva} />
                      </MDBox>
                    </MDBox>

                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '3fr 1fr 2fr' }}>
                      <MDBox >
                        <MDInput type="text" fullWidth label="Indirizzo" required name='indirizzo' disabled value={datiE.indirizzo} />
                      </MDBox>
                      <MDBox>
                        <MDInput type="text" fullWidth label="civico" required name='civico' disabled value={datiE.civico} />
                      </MDBox>
                      <MDBox >
                        <MDInput type="text" label="Dettaglio Indirizzo (frazione, c/o, piano, ...)" fullWidth name='indirizzo2' disabled value={datiE.indirizzo2} />
                      </MDBox>
                    </MDBox>


                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 2fr 1fr' }}>
                      <MDBox>
                        <MDInput type="text" label="CAP" fullWidth name='cap' disabled value={datiE.cap} />
                      </MDBox>
                      <MDBox>
                        <MDInput type="text" label="località" name='localita' fullWidth disabled value={datiE.localita} />
                      </MDBox>
                      <MDBox>
                        <MDInput type="text" label="provincia" name='provincia' fullWidth disabled value={datiE.provincia} />
                      </MDBox>
                    </MDBox>

                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr 1fr' }}>
                      <MDBox>
                        <MDInput type="text" label="Telefono" name='telefono' fullWidth disabled value={datiE.telefono} />
                      </MDBox>
                      <MDBox>
                        <MDInput type="text" label="Fax" name='fax' fullWidth disabled value={datiE.fax} />
                      </MDBox>
                      <MDBox>
                        <MDInput type="text" label="WWW" name='www' fullWidth disabled value={datiE.www} />
                      </MDBox>
                    </MDBox>

                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>

                      <MDBox mb={2} >
                        <MDInput type="text" label="Email" fullWidth required name='email' disabled value={datiE.email} />
                      </MDBox>
                      <MDBox>
                        <MDInput type="text" label="PEC" name='pec' fullWidth disabled value={datiE.pec} />
                      </MDBox>
                    </MDBox>

                    <MDBox mb={2}>
                      <MDTypography variant="button" fontWeight="regular" color="text"> Codici Merceologici</MDTypography>
                    </MDBox>
                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                      <MDBox>
                        <MDInput type="text" label="Codice Merceologico 1" name='codice_merceologico_01' fullWidth disabled value={datiE.cm_01} />
                      </MDBox>
                      <MDBox>
                        <MDInput type="text" label="Codice Merceologico 2" name='codice_merceologico_02' fullWidth disabled value={datiE.cm_02} />


                      </MDBox>
                    </MDBox>



                    <MDBox mb={2}>
                      <MDBox>
                        <MDInput type="text" label="Prodotti / Servizi in esposizione " fullWidth disabled value={datiE.prodotti_esposti} multiline rows={3} />
                      </MDBox>
                    </MDBox>

*/}

                    <MDBox mb={4}>

                      <MDButton variant="gradient" color="success" fullWidth>
                        <MDTypography variant="h5" fontWeight="regular" sx={{
                          color: "#FFFFFF"
                        }}>DATI AZIENDE RAPPRESENTATE</MDTypography>
                      </MDButton>
                    </MDBox>


                    <Card>
                    <MDBox mb={2} >
                    <Alert severity="info">
                      <MDTypography variant="button" fontWeight="regular">I Dati per il Catalogo devono essere inseriti ed inviati entro il 09 febbraio 2025.</MDTypography>                  
                      </Alert>
                      </MDBox>

                      <MDBox sx={{ display: 'flex', flexDirection: 'row', pt: 0}} mt={0} mb={1}>

                        <MDBox sx={{ flex: '1 1 auto' }} />
                     
                        <MDBox ml={2}>
                          <MDButton variant="outlined" color="black" onClick={aggiungi_azienda} >
                            + Aggiungi Azienda
                          </MDButton>
                        </MDBox>

                      </MDBox>


                      <CardContent>

                        {(listDatiC != null && listDatiC.length > 0) &&
                          <MDBox pt={1}>
                            <DataTable canSearch={false}
                              table={ppTableData}
                              isSorted={false}
                              entriesPerPage={false}
                              showTotalEntries={true}
                              noEndBorder
                            />      </MDBox>}
                        {(listDatiC == null || listDatiC.length === 0) &&
                          <MDBox style={{ height: "200px" }} textAlign='center'>
                            <MDTypography
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                            >
                              Non ci sono Aziende Rappresentate in archivio. Clicca su Nuovo per aggiungerne una!
                            </MDTypography></MDBox>
                        }


                      </CardContent>
                    </Card>




                    <MDBox mb={2}>
                    </MDBox>





                    <MDBox>

                    </MDBox>


                  </MDBox>
                </CardContent>

              </MDBox>

            </Card>
          </Grid >
        </Grid >
      </MDBox >

      <DatiCatalogoEditModal
        espositore_azienda={0}
        onSave={salva_espositore}
        id_catalogo={id_cat}
        onClose={handleClose2}
        show={show2}
        part={part}
        
      />
      <DatiCatalogoEditModal
        espositore_azienda={1}
        id_catalogo={id_cat}
        onSave={salva_azienda}
        onClose={handleClose3}
        show={show3}
      />



      <Footer />
    </DashboardLayout >
  );
}

export default DatiCatalogoVis;
