import { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";

import MDTypography from "common/components/MD/MDTypography";


// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";

//prop-types is a library for typechecking of props
// Data
import { useManifestazione } from "common/context/GlobalContext";
import DefaultInfoCard from "common/components/implemented/Cards/InfoCards/DefaultInfoCard";
import ExpoPartecipazioniService from "services/API/expo_partecipazioni/ExpoPartecipazioniService";
import base64ToArrayBuffer from "common/script/base64ToArrayBuffer";
import { Alert, AppBar } from "@mui/material";
import MDAlert from "common/components/MD/MDAlert/MDAlert";
import UtilityService from "services/API/utility/UtilityService";
import AuthService from "services/API/Auth/AuthService";




function ContrattoVis() {



  const { manifestazione } = useManifestazione();
 
 const navigate = useNavigate();
  const [part, setPart] = useState({
});

const stringa=  (<MDBox><MDTypography
variant="button"
fontWeight="regular"
color="text"
sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
>
  Dopo aver accettato il Regolamento Tecnico di Quartiere, 
Stampa il contratto e ricaricalo firmato in tutti i suoi punti   </MDTypography>
<MDButton  ml={3} onClick={() =>   navigate("/regolamento_tecnico")} color="success">Vai</MDButton>

</MDBox>);

const[contratto,setContratto]=useState(stringa);


useEffect(() => {
      
  seleziona_soggetto();

}, []);

async function seleziona_soggetto() {
  console.log("Cambiata pp->seleziono i dettagli :"+manifestazione);

      const response = await ExpoPartecipazioniService.seleziona_partecipazione(manifestazione);
      response.errors ? console.log(response.errors) : retrive(response.data);
 
}
function retrive(data) {

  setPart({
      idPartecipazione: data.idPartecipazione,
      id_expo_richiesta:data.id_expo_richiesta,
      id_utente: data.id_utente,
      nome_pdf_contratto:data.nome_pdf_contratto
  });

  console.log(":::"+data.nome_pdf_contratto);

if(data.nome_pdf_contratto!=null && !data.nome_pdf_contratto==""){
  setContratto( (
    <MDTypography
      variant="button"
      fontWeight="regular"
      color="text"
      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
      onClick={() => vedi_contratto(data.idPartecipazione)}
    >
      {data.nome_pdf_contratto}
    </MDTypography>
  ));}

  console.log("selezionata  " + part.idPartecipazione);

}

async function vedi_contratto(idPartecipazione) {
  if (AuthService.isTokenOk()) {
  ExpoPartecipazioniService.download_pdf_contratto(idPartecipazione)
    .then(response => {

      return response.data;
    })
    .then(blob => {

      var arrrayBuffer = base64ToArrayBuffer(blob); //data is the base64 encoded string
      var blobb = new Blob([arrrayBuffer], { type: "application/pdf" });
      var url = URL.createObjectURL(blobb);
      var a = document.createElement('a');
      a.href = url;
      a.target = "_blank";

      a.download = "Contratto.pdf";
      ;

      document.body.appendChild(a); // append the element to the dom
      a.click();
      a.remove(); // afterwards, remove the element  
    })
    .catch(error => {
      console.error(error);
    });


  }

}


async function download_pdf_rt() {
       
  if (AuthService.isTokenOk()) {
           
  UtilityService.seleziona_file_man(manifestazione,"R_TECNICO")
      .then(response => {

          return response.data;
      })
      .then(blob => {

          var arrrayBuffer = base64ToArrayBuffer(blob); //data is the base64 encoded string
          var blobb = new Blob([arrrayBuffer], { type: "application/pdf" });
          var url = URL.createObjectURL(blobb);
          var a = document.createElement('a');
          a.href = url;
          a.target = "_blank";
         
    
              a.download ="RegolamentoTecnicoDiQuartiere.pdf";
          document.body.appendChild(a); // append the element to the dom
          a.click();
          a.remove(); // afterwards, remove the element  
      })
      .catch(error => {
          console.error(error);
      });

    }


}



const regolamento_tecnico = (
    <MDButton variant="outlined" color="success"   onClick={download_pdf_rt} >
      Regolamento Tecnico di Quartiere
    </MDButton>

);



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderForm titolo={"I miei documenti per " + manifestazione} />
              <MDBox p={2}>
                  <MDAlert color ="success" >
                    Contratto di Partecipazione
                  </MDAlert>
                <DefaultInfoCard
                  color="success"
                  icon="drawicon"
                  title="Contratto"
                  description={"Il Contratto di Partecipazione a "+ manifestazione+" è da firmare e ricaricare entro il 19 Gennaio 2025."}
                  value={contratto}
                />
              </MDBox>
              <MDBox p={2}>
              <MDAlert color ="success" >
                   Regolamento Tecnico di Quartiere
                  </MDAlert>
                
              <DefaultInfoCard
                    color="success"
                    icon="drawicon"
                    title={regolamento_tecnico}
                    description={"Scarica il regolamento tecnico"}
                    value=""
                  />
              </MDBox>
              <MDBox p={2}>
              <MDAlert color ="success" >
                   Promemoria per gli espositori
                  </MDAlert>
                  <DefaultInfoCard
                        
                        color="success"
                        icon="alarmOn"
                        title={<Link to="https://far.umbriafiere.it/download/promemoria.pdf" target="_blank">
                        <MDButton variant="outlined" color="success"  >
                          Promemoria per gli espositori
                        </MDButton>
                      </Link>}
                        description={"Promemoria importante per tutti gli espositori!"}
                        value=""
                      />
              </MDBox>

            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}



export default ContrattoVis;
