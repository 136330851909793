import DashboardLayout from "layouts/DashboardLayout";
import Footer from "layouts/components/Footer";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

//validazione lato client

// Data
import { Card, Grid } from "@mui/material";
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";
import MDInput from "common/components/MD/MDInput";
import HeaderForm from "layouts/components/HeaderForm";
import Messaggio from "models/Messaggio";
import ComunicazioniService from "services/API/Comunicazioni/ComunicazioniService";

import AllegatiList from "common/components/implemented/Lists/AllegatiList";
import AuthService from "services/API/Auth/AuthService";


function MessaggioRead() {

    const location = useLocation();
    const navigate = useNavigate();

    const { modo, soggetto, righe, id_proposta, chiamante } = location.state;


    const [messaggio, setMessaggio] = useState(Messaggio);
    const [lista_allegati, setLista_allegati] = useState([{ idAllegato: 0, nome_allegato: '' }]);


    useEffect(() => {
         if (AuthService.isTokenOk()) {
        if (soggetto != null) {
            console.log("soggetto non nullo: id=" + soggetto.idMessaggio);
            retrive_soggetto();
        } else {
            console.log("soggetto nullo");
        }}
    }, [soggetto]);

    async function retrive_soggetto() {

        console.log("Cambiata msg->seleziono i dettagli :" + soggetto.idMessaggio);
        if (soggetto.idMessaggio != null) {
            const response = await ComunicazioniService.seleziona_dettagli_msg(soggetto.idMessaggio);
            //  console.log(response.data);
            response.errors ? console.log(response.errors) : retrive(response.data);


        }
    }

    function retrive(data) {
        console.log("RETRIVE =" + data.idMessaggio);

        setMessaggio({
            idMessaggio: data.idMessaggio,
            id_utente: data.id_utente,
            azienda: data.azienda,
            id_proposta: data.id_proposta,
            protocollo_proposta: data.protocollo_proposta,
            oggetto: data.oggetto,
            testo: data.testo,
            testo_email_notifica: data.testo_email_notifica,
            destinatario_email_notifica: data.destinatario_email_notifica,
            allegati: data.allegati,
            inviato: data.inviato, accettazione: data.accettazione,
            string_data_ins: data.string_data_ins,
            string_data_invio: data.string_data_invio,

        })

        console.log("selezionata  " + data.idMessaggio);

    }


    // effect runs when user state is updated
    useEffect(() => {



        setLista_allegati(messaggio.allegati);


    }, [messaggio]);

    const annulla = () => {
        console.log("ANNULLA");


        navigate("/" + chiamante, { state: { id_proposta: id_proposta } });

    }





    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HeaderForm titolo="Messaggio" exit={annulla} modo={modo} />


            <MDBox pt={6} pb={3}>



                <form >
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox pt={4} pb={3} px={3}>
                                    <MDBox component="form" role="form">
                                        <Grid container spacing={3}>



                                            <Grid item xs={12} sm={12} mb={2}>

                                                <MDInput type="text" label="Oggetto " fullWidth readOnly
                                                    name="oggetto" value={messaggio.oggetto} InputLabelProps={{ shrink: true }}
                                                    autocomplete="off" />


                                            </Grid>
                                            <Grid item xs={12} sm={12} mb={2}>

                                                <MDInput type="text" label="Testo della comunicazione" fullWidth
                                                    name='testo' multiline rows={3} InputLabelProps={{ shrink: true }} readOnly
                                                value={messaggio.testo}/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} mb={2}>
                                                <MDBox>

                                                    <AllegatiList title="Allegati" allegati={lista_allegati}></AllegatiList>

                                                </MDBox>
                                            </Grid>


                                            <Grid item xs={12} sm={6} mb={2}>

                                            </Grid>
                                            <Grid item xs={12} sm={6} mb={2}>

                                            </Grid>
                                        </Grid>
                                        <MDBox mb={2}>
                                        </MDBox>
                                        <MDBox sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} mt={4} mb={1}>

                                            <MDBox sx={{ flex: '1 1 auto' }} />
                                            <MDBox ml={2}>
                                                <MDButton variant="outlined" color="success" onClick={annulla} type="submit"  >
                                                    Indietro
                                                </MDButton>
                                            </MDBox>
                                            <MDBox ml={2}>

                                            </MDBox>

                                        </MDBox>

                                    </MDBox>
                                </MDBox>




                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </MDBox>




            <Footer />
        </DashboardLayout>
    );
}

export default MessaggioRead;