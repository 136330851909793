import React from 'react';
//prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import MDBox from "common/components/MD/MDBox";



export const NoneFilter = ({column})=>{

  const{filterValue,setFilter}=column

    return (

        <MDBox ml="auto">
       
      </MDBox>
    )

}

// Setting default values for the props of DataTable
NoneFilter.propTypes = {
    filter:PropTypes.bool,
    setFilter: PropTypes.func,
  };
  
  // Typechecking props for the DataTable
  NoneFilter.defaultProps = {

    filter:false,
    setFilter: null,
  };