import axios from 'axios';
import authHeader from 'services/API/Auth/AuthHeader';
import authHeaderMultipart from 'services/API/Auth/AuthHeaderMultipart';

const getManAperte = () => {
    console.log("getman aperte")
    return axios.get(axios.defaults.baseURL + '/utility/manifestazioni_aperte', { headers: authHeaderMultipart() });
}
const getAllManifestazioni = () => {
    //const h = authHeaderMultipart();
   // console.log(h);
  //  if (h != null) {
        return axios.get(axios.defaults.baseURL + '/utility/manifestazioni');
  //  }
   // else return null;
}
const createMan = (data) => {
    return axios.post(axios.defaults.baseURL + '/utility/manifestazione_ins', data, {
        headers: authHeaderMultipart()
    })
};

const updateMan = (nome, data) => {
    console.log("update= " + nome);
    console.log("data= " + data.pdf_regolamento);



    return axios.post(axios.defaults.baseURL + `/utility/manifestazione_upd/${nome}`, data, { headers: authHeaderMultipart() });
};


const seleziona_dettagli_man = (nome) => {

    console.log("seleziona_dettagli= " + nome);
    return axios.get(axios.defaults.baseURL + `/utility/manifestazione_seleziona_dettagli/${nome}`, { headers: authHeader() });

}
const seleziona_file_man = (nome, tipo) => {

    console.log("seleziona_regolamento= " + nome);
    const response = axios.get(axios.defaults.baseURL + `/utility/manifestazione_file/${nome}/${tipo}`, { headers: authHeader() }, { responseType: 'blob' },);
    console.log(response);
    return response;
}
const getAllCodiciM = () => {
    // console.log(authHeader());
    return axios.get(axios.defaults.baseURL + `/professioni/getAllCM`);
}
const getAllSpazi = () => {

    return axios.get(axios.defaults.baseURL + `/utility/getAllSpazi`, { headers: authHeader() });
}

const getAllTipologie = () => {
    return axios.get(axios.defaults.baseURL + '/utility/tipologie_espositive', { headers: authHeader() });
}
const download_file = (nome_file) => {

    // console.log("seleziona_pdf= " + nome_file);
    const response = axios.get(axios.defaults.baseURL + `/utility/downloadFile/${nome_file}`, { headers: authHeader() }, { responseType: 'blob' },);
    console.log("RESPONSE=" + response);
    return response;
}

const UtilityService = {
    getManAperte, getAllManifestazioni,
    createMan,
    updateMan,
    getAllCodiciM,
    seleziona_dettagli_man,
    seleziona_file_man, download_file,
    getAllTipologie, getAllSpazi
};

export default UtilityService;
