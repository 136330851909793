import { Alert, Icon } from "@mui/material";
import DataTable from "common/components/implemented/Tables/DataTable/DataTables";
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";
import MDTypography from "common/components/MD/MDTypography";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ComunicazioniService from "services/API/Comunicazioni/ComunicazioniService";

function MessaggiTable({messaggi=[]}) {

  
  //  console.log("MessaggiTable="+id_proposta);
  //per passarmi la variabiule se ho salvato e far comparire l'alert
  const location = useLocation();
  //console.log(location);
    const { state } = location != null ? location : { state: { submitted: false, salvato: 'NO' } }; // Read values passed on state
 
      const msgAttributes = [];
      if (messaggi.length > 0) {
    
        messaggi.forEach(msg => {
    
         let sentOrSend= "INVIATO";
         let edit=    (<Link to="/messaggioEdit" state={{ modo: 'vis', soggetto: msg, subject: null ,chiamante:"messaggi"}}
         >
           <MDButton variant="outlined" color={"success"} iconOnly={true} >
             <Icon>visibility</Icon>
           </MDButton>
         </Link>);
        
         if(!msg.inviato){
          sentOrSend=( <MDButton variant="outlined" color={"success"} iconOnly={true} >
            <Icon>send</Icon>
          </MDButton>);

            edit=( 
                <Link to="/messaggioEdit" state={{ modo: 'edit', soggetto: msg, subject: null,chiamante:"messaggi" }}>
            <MDButton variant="outlined" color={"success"} iconOnly={true} >
                <Icon>edit</Icon>
              </MDButton></Link>);
          }

        
     


        msgAttributes.push({
          data_invio:msg.string_data_invio,
          oggetto: msg.oggetto,
          accettazione: msg.accettazioneSiNo,
          invio:sentOrSend,
          edit:edit,
         azienda:msg.azienda
         
        })
      });
    
      }
    
      const mcTableData = {
        columns: [
          { Header: "Data invio", accessor: "data_invio", width: "10%", align: "left" },
            { Header: "Destinatario", accessor: "azienda", width: "20%", align: "left" },
          { Header: "Oggetto Comunicazione", accessor: "oggetto", align: "left" },     
          { Header: "Accettazione", accessor: "accettazione", width: "10%", align: "center" },
          { Header: "", accessor: "edit", align: "center", width: "5%", enableGlobalFilter: false },
          { Header: "Invio", accessor: "invio", align: "center", width: "5%", enableGlobalFilter: false },
         
        ],
    
        rows: msgAttributes,
    
    
      };
    
      const mypagination = { color: 'success', variant: "contained" };
     
     

return (

    <MDBox>
    <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">

      {(state != null && state.submitted  && (state.salvato==='INS' || state.salvato==='MOD') ) &&

        <Alert severity="success">Messaggio salvato con successo!</Alert>}
         {(state != null && state.submitted  && state.salvato==='DEL')  &&

<Alert severity="success">Messaggio eliminata con successo!</Alert>}
    </MDBox>
    <MDBox pt={3}>
      <DataTable canSearch={true}
        table={mcTableData}
        isSorted={false}
    
        entriesPerPage={false}
        showTotalEntries={true}
        pagination={mypagination}
        noEndBorder
      />
    </MDBox>
    {(messaggi == null || messaggi.length === 0) &&
      <MDBox style={{ height: "200px" }} textAlign='center'>
        <MDTypography
          variant="button"
          fontWeight="regular"
          color="text"
          sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
        >
          Non ci sono Messaggi in archivio. Clicca su Nuovo per crearne uno!
        </MDTypography></MDBox>
    }
    </MDBox>

);

}
export default MessaggiTable;