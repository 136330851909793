import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";

import MDTypography from "common/components/MD/MDTypography";


// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";


import Alert from '@mui/material/Alert';
import FormControl from "@mui/material/FormControl";
import NativeSelect from '@mui/material/NativeSelect';

import loading_image from 'common/assets/images/icons/loading-green-loading.gif'
// Data
import ExpoRichiesteService from "services/API/ExpoRichieste/ExpoRichiesteService";
import UtilityService from "services/API/utility/UtilityService";

import { Chip, IconButton, MenuItem, Select } from "@mui/material";
import ReactHookFormSelect from "common/components/mycomponents/ReactHookFormSelect";
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import ExpoRichiesteTable from "./ExpoRichiesteTable";
import { ColumnFilter } from "common/components/implemented/Tables/DataTable/ColumnFilter";

import { SelectColumnFilterStato } from "./SelectColumnFilterStato";
import { SelectColumnFilterTipo } from "./SelectColumnFilterTipo";
import base64ToArrayBuffer from "common/script/base64ToArrayBuffer";
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import { SelectColumnFilterMsg } from "./SelectColumnFilterMsg";
import ComunicazioniService from "services/API/Comunicazioni/ComunicazioniService";
import { NoneFilter } from "./NoneFilter";
import { useManifestazione } from "common/context/GlobalContext";


function ElencoRichieste() {

  const [proposte, setProposte] = useState([]);

  //const { manifestazione} = useManifestazione( );
  const  manifestazione= "Agriumbria 2025";
  

  const [loading, setLoading] = useState(true);



  //per passarmi la variabiule se ho salvato e far comparire l'alert
  const location = useLocation();
  //console.log(location);

  const { state } = location != null ? location : { state: { submitted: false, salvato: 'NO', indietro: false } }; // Read values passed on state


  useEffect(() => {

      console.log("FILTRO");
      setProposte([]);
      setLoading(true);

      ExpoRichiesteService.getAllRichieste(manifestazione, 'INVIATA', 'NON ACCETTATO')
        .then(response => {
          // console.log(response.data);
          setProposte(response.data);
          setLoading(false);
        })
        .catch(e => {
          console.log(e);
          setLoading(false);
        });
    
  }, []);

  

  async function download_pdf_allegato(idAllegato, nome_file) {

    ComunicazioniService.download_allegato(idAllegato)
      .then(response => {

        return response.data;
      })
      .then(blob => {

        var arrrayBuffer = base64ToArrayBuffer(blob); //data is the base64 encoded string
        var blobb = new Blob([arrrayBuffer], { type: "application/pdf" });
        var url = URL.createObjectURL(blobb);
        var a = document.createElement('a');
        a.href = url;
        a.target = "_blank";

        a.download = nome_file;
        ;

        document.body.appendChild(a); // append the element to the dom
        a.click();
        a.remove(); // afterwards, remove the element  
      })
      .catch(error => {
        console.error(error);
      });




  }



  const ppAttributes = [];

  if (proposte.length > 0) {
    proposte.forEach(prop => {

      let action = (
        <MDBox>
          <Link to="/expo_richiestaMessage" state={{ id_proposta: prop.idRichiesta, chiamante:'expo_richieste_na_admin' }}>
            <MDButton variant="outlined" color={"success"} iconOnly={true}  >
              < AttachEmailIcon />
            </MDButton>   </Link>
          <Link to="/expo_richiestaPDFVis" state={{ modo: 'vis', soggetto: prop, subject: prop.manifestazione,chiamante:'expo_richieste_na_admin' }}>
            <MDButton variant="outlined" color={"success"} iconOnly={true} >
              <Icon>visibility</Icon>
            </MDButton>
          </Link>
        </MDBox>
      );



      let lettera_na = "";
   // console.log(prop.esito);
    //console.log(prop.esito.nome_file);
      if (prop.id_allegato_esito != null && prop.nome_file_esito) {

        lettera_na = (
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
            onClick={() => download_pdf_allegato(prop.id_allegato_esito, prop.nome_file_esito)}
          >
            {prop.nome_file_esito}
          </MDTypography>
        );
      }

      let msg_na = "DA SCRIVERE";
      if (prop.id_messaggio != null && prop.id_messaggio != 0) {
        msg_na = "DA INVIARE";
        if (prop.inviato) {
          msg_na = "INVIATO IL " + prop.string_data_invio;
        }
      }


      ppAttributes.push({
        er: prop,
        mqr: prop.mqr,
        protocollo: prop.protocollo,
        azienda: prop.ragione_sociale,
        data_invio: prop.string_data_agg,
        data_um: prop.string_data_agg_accettazione,
        note: prop.note,
        cm_string: prop.cm_string,
        stato: prop.stato_accettazione,
        av: prop.anticipo_controllato_string,
        etichetta: prop.etichetta,
        color: prop.color,
        action: action,
        esigenze_espositive: prop.esigenze_espositive,
        tipologia_espositiva_string: prop.tipologia_stand,
        msg_na: msg_na,
        lettera_na: lettera_na,
      })
    });
  }



  const ppTableData = {
    rows: ppAttributes,

    columns: [

      { Header: "Protocollo", accessor: "protocollo", Filter: ColumnFilter, width: "2%", align: "left", id: "proposte_0" },
      { Header: "Azioni", accessor: "action", Filter: NoneFilter, align: "center", enableGlobalFilter: false, id: "proposte_1", disableSortBy: true },
      { Header: "Azienda", accessor: "azienda", Filter: ColumnFilter, align: "left", with: "50%", id: "proposte_2" },
      { Header: "Codice Merceologico", accessor: "cm_string", Filter: ColumnFilter, width: "10%", align: "left", id: "proposte_3" },
      {
        Header: "Tipologia Espositiva Richiesta", accessor: "tipologia_espositiva_string",
        id: "proposte_4",

        Filter: SelectColumnFilterTipo,
        Cell: ({ row }) => {
          return (
            <MDBox>
              <MDBox> <MDTypography variant="button"
                fontWeight="regular"
                color="text">{row.original.tipologia_espositiva_string}</MDTypography></MDBox>
              {row.original.esigenze_espositive &&
                <MDBox>  <MDTypography variant="button"
                  fontWeight="regular"
                  color="text">*** {row.original.esigenze_espositive.substring(0, 20)}...</MDTypography> </MDBox>}
              {row.original.note &&
                <MDBox>  <MDTypography variant="button"
                  fontWeight="regular"
                  color="text">*** {row.original.note.substring(0, 20)}...</MDTypography> </MDBox>}
            </MDBox>

          );
        },
        width: "15%", align: "left"
      },
      { Header: "Mq richiesti", accessor: "mqr", Filter: NoneFilter, align: "left", width: "5%", id: "proposte_7" },
      { Header: "Stato", accessor: "stato", Filter: NoneFilter, align: "left", width: "5%", id: "proposte_5" },
      { Header: "Anticipo Controllato", accessor: "av", Filter: ColumnFilter, align: "left", width: "5%", id: "proposte_6" },
      {
        Header: "Etichetta", accessor: "etichetta", Filter: ColumnFilter,
        Cell: ({ row }) => {
          return (
            <Chip label={row.original.etichetta} color={row.original.color} />

          );
        },
        align: "left", width: "5%", id: "proposte_8"
      },
      { Header: "Lettera Non Accettazione", accessor: "lettera_na", Filter: NoneFilter, width: "2%", align: "left", id: "proposte_9" },
      { Header: "Msg Non Accettazione", accessor: "msg_na", Filter: SelectColumnFilterMsg, width: "2%", align: "left", id: "proposte_10" },

    ],



  };

  const mypagination = { color: 'success', variant: "contained" };

  


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderForm titolo="Proposte di Partecipazione NON ACCETTATE" add="" modo="vis" />


              <MDBox pt={3}>
                <ExpoRichiesteTable canSearch={true}
                  table={ppTableData}
                  nome_tabella="proposte_na"
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={mypagination}
                  noEndBorder
                  responsive={true}
                  first_load={state == null || !state.indietro}
                  filtro_server={true}
            
                  canExport={true}
                  canSend={true}
                  enableColumnFilters={true}
                  chiamante='expo_richieste_na_admin'

                />
              </MDBox>
              {loading &&
                <MDBox opacity={1} textAlign="center">
                  <img src={loading_image} alt="loading" width={100} />
                </MDBox>
              }

              {(proposte == null || proposte.length === 0) &&
                <MDBox style={{ height: "200px" }} textAlign='center'>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    Non ci sono Proposte di partecipazione non accettate in archivio.
                  </MDTypography></MDBox>
              }
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}



export default ElencoRichieste;
