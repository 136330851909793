import React from 'react';
//prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import MDBox from "common/components/MD/MDBox";
import { MenuItem, Select } from '@mui/material';
export const SelectColumnFilterStatoOK = ({ column }) => {

  const { filterValue, setFilter } = column

  return (

    <MDBox ml="auto">

      <Select name="stato_accettazione" id="stato_accettazione"
        onChange={e => {
          console.log(e.target.value);
          sessionStorage.setItem("filter_"+column.id,e.target.value);  
          setFilter(e.target.value);
        }}
        value={filterValue || 'ACCETTATO'}
      >

        <MenuItem key="ACCETTATO" value="ACCETTATO">ACCETTATO</MenuItem>
        <MenuItem key="ACCETTATO CON MODIFICHE" value="ACCETTATO CON MODIFICHE">ACCETTATO CON MODIFICHE</MenuItem>
        <MenuItem key="RIAMMESSO" value="RIAMMESSO">RIAMMESSO</MenuItem>


      </Select>


    </MDBox>
  )

}

// Setting default values for the props of DataTable
SelectColumnFilterStatoOK.propTypes = {

  filter: PropTypes.string,
  setFilter: PropTypes.func,
};

// Typechecking props for the DataTable
SelectColumnFilterStatoOK.defaultProps = {
  filter: '',
  setFilter: null,
};