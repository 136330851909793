import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom'
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import MDBox from "common/components/MD/MDBox";
import MDTypography from "common/components/MD/MDTypography";
import MDInput from "common/components/MD/MDInput";
import MDButton from "common/components/MD/MDButton";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Alert from '@mui/material/Alert';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ReactHookFormCheckBox from "common/components/mycomponents/ReactHookFormCheckBox"
import HeaderForm from "layouts/components/HeaderForm";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


//validazione lato client
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Data
import UtilityService from "services/API/utility/UtilityService";
import base64ToArrayBuffer from "common/script/base64ToArrayBuffer";

function ManifestazioneEdit() {
    const location = useLocation();
    const navigate = useNavigate();
   // console.log("STATE+" + location.state.modo);
    //  console.log("STATE+"+location.state.soggetto.email);
    const { modo, soggetto } = location.state;

    // user state for form
    const [manifestazione, setManifestazione] = useState({ nome_manifestazione: '', iscrizioni_aperte: '', pdf_regolamento: '', nome_pdf_regolamento: '' ,
        pdf_regolamento_tecnico: '', nome_pdf_regolamento_tecnico: '' ,
    });

    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);



    const validationSchema = Yup.object().shape({
        nome_manifestazione: Yup.string().required('Nome richiesto').max(100, 'Nome non deve superare 100 caratteri'),
        
        pdf_regolamento: Yup.mixed().when("$isPresent", {
            is: (val) => {
                return modo === 'new';
            },
            then: (s) => s.required('VALIDATION_FIELD_REQUIRED')
                            .test("fileSize", "The file is too large", (value) => {
                                return value && value[0].size <= 2000000;
                            })
                            .test("type", "Only the following formats are accepted: .pdf ", (value) => {
                                return value && (
                                    value[0].type === 'application/pdf'
                                );
                            }),
            otherwise: (s) => s,
        }),
      
    /*  
        pdf_regolamento: Yup.mixed()
            .required('VALIDATION_FIELD_REQUIRED')
            .test("fileSize", "The file is too large", (value) => {
                return value && value[0].size <= 2000000;
            })
            .test("type", "Only the following formats are accepted: .pdf ", (value) => {
                return value && (
                    value[0].type === 'application/pdf'
                );
            }),*/


            pdf_regolamento_tecnico: Yup.mixed().nullable()
            .test("fileSize", "The file is too large", (value) => {
               return value==null || value[0].size <= 5000000;
            })
            .test("type", "Only the following formats are accepted: .pdf ", (value) => {
                return (
                    value==null || value[0].type === 'application/pdf'      );
            }),
    });


    useEffect(() => {
        if (soggetto != null) {
            console.log("soggetto non nullo: id=" + soggetto.nome_manifestazione);
            retrive_soggetto();
        } else {
            console.log("soggetto nullo");
        }
    }, [soggetto]);

    async function retrive_soggetto() {
        console.log("Cambiata man->seleziono i dettagli :" + soggetto.nome_manifestazione);
        if (soggetto.nome_manifestazione != null) {
            const response = await UtilityService.seleziona_dettagli_man(soggetto.nome_manifestazione);
          //  console.log(response.data);
            response.errors ? console.log(response.errors) : retrive(response.data);


        }
    }

    function retrive(data) {
        console.log("RETRIVE =" + data.nome_manifestazione);

        setManifestazione({
            nome_manifestazione: data.nome_manifestazione,
            iscrizioni_aperte: data.iscrizioni_aperte,
            pdf_regolamento: data.pdf_regolamento, nome_pdf_regolamento: data.nome_pdf_regolamento,
            pdf_regolamento_tecnico: data.pdf_regolamento_tecnico, nome_pdf_regolamento_tecnico: data.nome_pdf_regolamento_tecnico,
        })

        console.log("selezionata  " + data.nome_manifestazione);

    }



    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        setValue("nome_manifestazione", manifestazione.nome_manifestazione);
        setValue("iscrizioni_aperte", manifestazione.iscrizioni_aperte);
        setValue("pdf_regolamento", manifestazione.pdf_regolamento);
        setValue("nome_pdf_regolamento", manifestazione.nome_pdf_regolamento);
        setValue("pdf_regolamento_tecnico", manifestazione.pdf_regolamento_tecnico);
        setValue("nome_pdf_regolamento_tecnico", manifestazione.nome_pdf_regolamento_tecnico);
    }, [manifestazione]);


    const {
        register, //register inputs
        reset, setValue, control,
        handleSubmit,//handle form submit
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
    });


    //sono quelli lato server
    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);


    const insert = (formData) => {
        setError(null);
        var data = {
            nome_manifestazione: formData.nome_manifestazione,
            iscrizioni_aperte: formData.iscrizioni_aperte,
            pdf_regolamento: formData.pdf_regolamento,
            pdf_regolamento_tecnico: formData.pdf_regolamento_tecnico,


        };

        if (modo === 'new') {
            console.log("Inserisci ");
            UtilityService.createMan(data)
                .then(response => {
                    handleResponse(response, 'INS');
                })
                .catch(errore => {
                    handleError(errore);
                });
        } else {
            console.log("update manifestazione ");
            console.log("iscrizioni_aperte="+data.iscrizioni_aperte);
            UtilityService.updateMan(manifestazione.nome_manifestazione, data)
                .then(response => {
                    handleResponse(response, 'MOD');
                })
                .catch(errore => {
                    handleError(errore);
                });

        }
    };

    function handleClose() {

        setOpen(false);
    }
    function handleConfirm() {

        setConfirm(true);


        setOpen(false);

        deleteLoc();
    }

    const handleOpen = () => {
        console.log("OPEN DIALOG");
        setOpen(true);
    }

    const deleteLoc = () => {
        setError(null);

        if (modo === 'edit') {

            console.log("delete loc");
            UtilityService.remove(manifestazione.nome_manifestazione)
                .then(response => {
                    console.log("handleResponse");
                    handleResponse(response, 'DEL');
                })
                .catch(errore => {
                    console.log("handleError");
                    handleError(errore);
                });


        }
    };


    function handleResponse(response, op) {
        console.log(response.data);
        navigate(`/manifestazioni`, { state: { submitted: true, salvato: op } });
    }

    function handleError(error) {
        setError(error);
        console.log("ERRORE" + error);
        console.log(error.code);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log("ERROR:RESPONSE" + error.response.data);
            setErrorMessage(error.response.data);

        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log("ERROR:REQUEST" + error.request);
            setErrorMessage(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            setErrorMessage(error.message);
        }

    }

    const annulla = () => {
        console.log("ANNULLA");
        navigate("/manifestazioni");

    }

    const download_pdf_regolamento=()=>{
        download_pdf("REGOLAMENTO");

    }
    const download_pdf_rt=()=>{
        download_pdf("R_TECNICO");

    }

    async function download_pdf(tipo_file) {
       
   
           
            UtilityService.seleziona_file_man(manifestazione.nome_manifestazione,tipo_file)
                .then(response => {

                    return response.data;
                })
                .then(blob => {

                    var arrrayBuffer = base64ToArrayBuffer(blob); //data is the base64 encoded string
                    var blobb = new Blob([arrrayBuffer], { type: "application/pdf" });
                    var url = URL.createObjectURL(blobb);
                    var a = document.createElement('a');
                    a.href = url;
                    a.target = "_blank";
                    if(tipo_file==="REGOLAMENTO")
                    a.download = manifestazione.nome_pdf_regolamento;
                    if(tipo_file==="R_TECNICO")
                        a.download = manifestazione.nome_pdf_regolamento_tecnico;
                    document.body.appendChild(a); // append the element to the dom
                    a.click();
                    a.remove(); // afterwards, remove the element  
                })
                .catch(error => {
                    console.error(error);
                });



        
    }




    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                    <DialogTitle id="alert-dialog-title">
                        Eliminare questo record?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Sei sicuro di voler eliminare questo record?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MDButton onClick={handleClose}>No</MDButton>
                        <MDButton onClick={handleConfirm} autoFocus>
                            SI
                        </MDButton>
                    </DialogActions>
                </Dialog>



                <form >
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <HeaderForm titolo="Manifestazioni" save={handleSubmit(insert)} remove={handleOpen} exit={annulla} modo={modo} />


                                {error !== null &&
                                    <Alert color="error"><ErrorOutlineOutlinedIcon fontSize="small" />&nbsp;{errorMessage}</Alert>
                                }
                                <MDBox pt={4} pb={3} px={3}>
                                    <MDBox component="form" role="form">
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12} mb={2}>

                                                <MDInput type="text" label="Nome Manifestazione" fullWidth name="nome_manifestazione" 
                                                {...register('nome_manifestazione')} autocomplete="off"
                                               
                                                    error={errors.nome_manifestazione ? true : false} InputLabelProps={{ shrink: true }} />
                                                {
                                                    errors.nome_manifestazione ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.nome_manifestazione?.message}</span> : ''
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={12} mb={2}>

                                                <MDInput
                                                    type="file"
                                                    label="PDF Regolamento" fullWidth id="pdf_regolamento" 
                                                    autocomplete="off"  {...register('pdf_regolamento')}
                                                    name="pdf_regolamento" InputLabelProps={{ shrink: true }}
                                                
                                                />
                                          
                                                <MDTypography
                                                    variant="button"
                                                    fontWeight="regular"
                                                    color="text"
                                                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                                    onClick={download_pdf_regolamento}
                                                >
                                                    {manifestazione.nome_pdf_regolamento}
                                                </MDTypography>

                                                {
                                                    errors.pdf_regolamento ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.pdf_regolamento?.message}</span> : ''
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={12} mb={2}>

<MDInput
    type="file"
    label="PDF Regolamento Tecnico di Quartiere" fullWidth id="pdf_regolamento_tecnico" 
    autocomplete="off"  {...register('pdf_regolamento_tecnico')}
    name="pdf_regolamento_tecnico" InputLabelProps={{ shrink: true }}

/>

<MDTypography
    variant="button"
    fontWeight="regular"
    color="text"
    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
    onClick={download_pdf_rt}
>
    {manifestazione.nome_pdf_regolamento_tecnico}
</MDTypography>

{
    errors.pdf_regolamento_tecnico ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.pdf_regolamento_tecnico?.message}</span> : ''
}
</Grid>
                                            <Grid item xs={12} sm={6} mb={2}>
                                                <ReactHookFormCheckBox
                                                    label={<MDBox>
                                                        <MDTypography
                                                            variant="button"
                                                            fontWeight="regular"
                                                            color="text"
                                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                                        >
                                                            &nbsp;&nbsp;Iscrizioni Aperte&nbsp;
                                                        </MDTypography>

                                                    </MDBox>}
                                                    name="iscrizioni_aperte"
                                                    control={control} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} mb={2}>

                                            </Grid>
                                            <Grid item xs={12} sm={6} mb={2}>

                                            </Grid>
                                        </Grid>


                                    </MDBox>
                                </MDBox>

                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </MDBox>


            <Footer />
        </DashboardLayout>
    );
}

export default ManifestazioneEdit;