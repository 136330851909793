import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import AuthService from "services/API/Auth/AuthService";
import EPService from 'services/API/expo_partecipazioni/ExpoPartecipazioniService';

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";

import DashboardLayout from "layouts/DashboardLayout";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
// @mui material components
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Grid from "@mui/material/Grid";
import ExpoRichiesteVis from "pages/riservata/expo_richieste/ExpoRichiesteVis";

//validazione lato client
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

//script
import currencyToDouble from "common/script/currencyToDouble";
import base64ToArrayBuffer from "common/script/base64ToArrayBuffer";
import SpazioComponent from "../expo_partecipazioni/SpazioComponent";
import MDInput from "common/components/MD/MDInput";
import ReactHookFormSelect from "common/components/mycomponents/ReactHookFormSelect";
import { MenuItem } from "@mui/material";
import UtilityService from "services/API/utility/UtilityService";
import ReactHookFormCheckBox from "common/components/mycomponents/ReactHookFormCheckBox";
import MDTypography from "common/components/MD/MDTypography";


const ExpoPartecipazioneAssign = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const { modo, soggetto, subject, chiamante } = location.state;


    // user state for form
    const [part, setPart] = useState({
        idPartecipazione: 0,

        id_utente: AuthService.getCurrentUser().id,
        manifestazione: subject,
        ragione_sociale: '',

        richieste: [{
            idContratto: 0,
            idRichiesta: 0,
            id_utente: AuthService.getCurrentUser().id,
            manifestazione: subject,
            ragione_sociale: '',
        }]
    });
    const [richiesta0, setRichiesta0] = useState({
        idRichiesta: 0, ragione_sociale: '', rappresentante_legale: '', indirizzo: '', civico: '',
        indirizzo2: '', cap: '', localita: '', provincia: '', cf: '', piva: '', telefono: '', fax: '', www: '',
        email: '', pec: '', codice_sdi: '', responsabile_fiera: '', responsabile_fiera_telefono: '', responsabile_fiera_cell: '',
        responsabile_fiera_email: '', cm_01: '', cm_01_descrizione: '', cm_02: '', cm_02_descrizione: '', cm_03: '', cm_03_descrizione: '',
        cm_04: '', cm_04_descrizione: '', prodotti_servizi: '', note: '', esigenze_espositive: '', iban: ''
    });

    const [lista_spazi, setLista_spazi] = useState([
        { nome_spazio: '' },
    ]);

    /*---------------------------------------CARICAMENTO DELLE COSE CHE SERVONO-------------------------------------*/
    useEffect(() => {
        console.log("carico gli spazi");

        async function fetchInitialData2() {
            const response = await UtilityService.getAllSpazi();
            response.errors ? console.log(response.errors) : setLista_spazi([...response.data]);
        }
        fetchInitialData2();

    }, []);


    /* ----------------------------CARICAMENTO E RIEMPIMENTO CAMPI------------------------------------------------- */

    useEffect(() => {
        if (soggetto != null) {
            console.log("soggetto non nullo: id=" + soggetto.idPartecipazione);
            seleziona_soggetto();
        } else {
            console.log("soggetto nullo");
        }
    }, [soggetto]);

    async function seleziona_soggetto() {
        console.log("Cambiata pp->seleziono i dettagli :" + soggetto.idPartecipazione);
        if (soggetto.idPartecipazione !== 0) {
            const response = await EPService.seleziona(soggetto.idPartecipazione);
            response.errors ? console.log(response.errors) : retrive(response.data);
        }
    }




    function retrive(data) {


        console.log(data);

        setPart({
            idPartecipazione: data.idPartecipazione,
            id_utente: data.id_utente,
            manifestazione: data.manifestazione,
            ragione_sociale: data.ragione_sociale,
            richieste: data.richieste,
            nome_pdf_contratto: data.nome_pdf_contratto,
            data_contratto: data.data_contratto,
            contratto_protocollato: data.contratto_protocollato,
            saldato:data.saldato
        });


    }


    /* ----------------------------VALIDAZIONE E SALVATAGGIO----------------------------------------------------------------------- */


    const validationSchema = Yup.object().shape({

    });



    const {
        register, //register inputs
        getValues, setValue, control,
        handleSubmit,//handle form submit
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });



    useEffect(() => {

        console.log("Cambiata pp->riempo i campi  " + part.idPartecipazione);
        setRichiesta0(part.richieste[0]);
        part.richieste.map((r) => {
            if (r.spazioAssegnatoDto != null) {
                const name = "spazio_" + r.idRichiesta;
                setValue(name, r.spazioAssegnatoDto.spazio);

                const num = "numero_" + r.idRichiesta;
                setValue(num, r.spazioAssegnatoDto.numero);
            }
        });

        setValue("contratto_protocollato", part.contratto_protocollato);
        setValue("saldato", part.saldato);

    }, [part]);

    /*------------------------FUNZIONALITA PAGINA------------------------------------------- */


    async function download_pdf_contratto(idPartecipazione) {
        if (AuthService.isTokenOk()) {
        EPService.download_pdf_contratto(idPartecipazione)
            .then(response => {

                return response.data;
            })
            .then(blob => {

                var arrrayBuffer = base64ToArrayBuffer(blob); //data is the base64 encoded string
                var blobb = new Blob([arrrayBuffer], { type: "application/pdf" });
                var url = URL.createObjectURL(blobb);
                var a = document.createElement('a');
                a.href = url;
                a.target = "_blank";
                a.download = "Contratto.pdf";
                document.body.appendChild(a); // append the element to the dom
                a.click();
                a.remove(); // afterwards, remove the element  
            })
            .catch(error => {
                console.error(error);
            });

        }


    }






    /* ----------------------------SALVATAGGIO E REGISTRAZIONE------------------------------------------------- */




    const [submitted, setSubmitted] = useState(false);


    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);



    const salva_pp = (formData) => {
        if (AuthService.isTokenOk()) {

        console.log("salva :::::::: MODO=" + modo);
        setError(null);


        part.richieste.map((r) => {
            console.log("salva :::::::: MODO=" + modo);
            const name = "spazio_" + r.idRichiesta;
            if (r.spazioAssegnatoDto != null)
                r.spazioAssegnatoDto.spazio = formData[name];

            const num = "numero_" + r.idRichiesta;
            if (r.spazioAssegnatoDto != null)
                r.spazioAssegnatoDto.numero = formData[num];
        });

        var data = {
            idPartecipazione: part.idPartecipazione,
            manifestazione: part.manifestazione,
            richieste: part.richieste,
            contratto_protocollato: formData.contratto_protocollato,
            saldato: formData.saldato,
        };



        if (modo === 'edit') {

            EPService.salva_spazio(part.idPartecipazione, data)
                .then(response => {
                    handleResponse(response, 'OK');
                })
                .catch(errore => {
                    handleError(errore);
                });

        }
    }

    }

    function handleResponse(response, op) {
        console.log(response.data);
        setSubmitted(true);

        navigate("/expo_partecipazioni_admin", { state: { submitted: true, salvato: op, indietro: true } });
    }


    function handleError(error) {
        setError(error);
        console.log("ERRORE" + error);
        console.log(error.code);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log("ERROR:RESPONSE" + error.response.data);
            setErrorMessage(error.response.data);

        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log("EEROR:REQUEST" + error.request);
            setErrorMessage(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            setErrorMessage(error.message);
        }

    }




    /* ------------------------------navigazione -----------------------------------------------------------------*/


    const annulla = () => {
        navigate("/" + chiamante, { state: { indietro: true } });
    }

    const handleKeyDown = (e) => {
        const { key, target } = e

        if (key !== "Enter" || target instanceof HTMLTextAreaElement) {
            return
        }

        e.preventDefault()
    }


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>

                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card >
                            <HeaderForm titolo={"Dati Partecipazione " + part.manifestazione} modo='edit'
                                save={handleSubmit(salva_pp)}
                                exit={annulla} />
                            <MDBox pt={1} pb={3} px={3} component="form" role="form" onKeyDown={handleKeyDown}>
                                <CardContent>
                                    <MDBox>
                                        <MDBox opacity={1} textAlign="center">

                                        </MDBox>
                                        <MDBox pt={4} pb={3} px={3} >

                                            <MDBox mt={5}>




                                                <ExpoRichiesteVis prop={richiesta0}></ExpoRichiesteVis>


                                                <MDBox>

                                                    {part != null && part.richieste.map((richiesta) => (
                                                        <MDBox>
                                                            <SpazioComponent
                                                                key={richiesta.idRichiesta}
                                                                id={richiesta.idRichiesta}
                                                                richiesta={richiesta}
                                                            />


                                                            <MDBox mb={2} mt={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr ' }}>

                                                                <ReactHookFormSelect
                                                                    id={"spazio_" + richiesta.idRichiesta}
                                                                    name={"spazio_" + richiesta.idRichiesta}
                                                                    label="Spazio"
                                                                    control={control}
                                                                    defaultValue=''
                                                                    variant="outlined">
                                                                    <MenuItem
                                                                        key={''}
                                                                        value={null}
                                                                    >
                                                                        -
                                                                    </MenuItem>
                                                                    {lista_spazi.map((s) => (
                                                                        <MenuItem
                                                                            key={s.codiceSpazio}
                                                                            value={s.codiceSpazio}
                                                                        >
                                                                            {s.codiceSpazio}
                                                                        </MenuItem>
                                                                    ))}
                                                                </ReactHookFormSelect>
                                                                <MDInput type="text" label="Numero" name={"numero_" + richiesta.idRichiesta} fullWidth
                                                                    {...register("numero_" + richiesta.idRichiesta)} InputLabelProps={{ shrink: true }} />
                                                            </MDBox>
                                                        </MDBox>
                                                    ))}

                                                    {/*contratto*/}
                                                    <MDBox sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr ' }}>
                                                        <MDTypography color='success' sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>
                                                            &nbsp;&nbsp;Contratto:&nbsp;
                                                        </MDTypography>

                                                        {(part.nome_pdf_contratto != null) &&
                                                            <MDBox sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr ' }}>
                                                                <MDBox>
                                                                    <MDTypography
                                                                        variant="button"
                                                                        fontWeight="regular"
                                                                        color="text"
                                                                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                                                        onClick={() => download_pdf_contratto(part.idPartecipazione)}
                                                                    > Ric. il {part.data_contratto}  :
                                                                        [Contratto.pdf]
                                                                    </MDTypography>


                                                                </MDBox>
                                                                <MDBox >
                                                                    <ReactHookFormCheckBox label={<MDBox><MDTypography variant="button"
                                                                        fontWeight="regular" color="text"
                                                                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>
                                                                        &nbsp;&nbsp;Contratto Protocollato&nbsp;
                                                                    </MDTypography>
                                                                    </MDBox>} name="contratto_protocollato" control={control} /></MDBox>

                                                            </MDBox>
                                                        }</MDBox>
                                                                   {/*saldo*/}
                                                    <MDBox sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                                                        <MDTypography color='success' sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>
                                                            &nbsp;&nbsp;Saldo:&nbsp;
                                                        </MDTypography>

                                                            <MDBox sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                                                            <MDBox></MDBox>
                                                                <MDBox>
                                                                    <ReactHookFormCheckBox label={<MDBox><MDTypography variant="button"
                                                                        fontWeight="regular" color="text"
                                                                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>
                                                                        &nbsp;&nbsp;Saldo effettuato&nbsp;
                                                                    </MDTypography>
                                                                    </MDBox>} name="saldato" control={control} /></MDBox>

                                                            </MDBox>
                                                        </MDBox>

                                                </MDBox>

                                                <MDBox sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} mt={4} mb={1}>
                                                    <MDButton variant="outlined" color="success" onClick={annulla} >
                                                        annulla
                                                    </MDButton>
                                                    <MDBox sx={{ flex: '1 1 auto' }} />
                                                    <MDBox ml={2}>

                                                    </MDBox>
                                                    <MDBox ml={2}>
                                                        <MDButton variant="gradient" color="success" onClick={handleSubmit(salva_pp)} type="submit"  >
                                                            Salva
                                                        </MDButton>
                                                    </MDBox>

                                                </MDBox>








                                            </MDBox>

                                        </MDBox>



                                    </MDBox>


                                </CardContent>
                            </MDBox>
                        </Card>
                    </Grid></Grid></MDBox>
            <Footer />
        </DashboardLayout>
    )
}


export default ExpoPartecipazioneAssign;