

export default function authHeaderMultipart() {


  const user = JSON.parse(localStorage.getItem('user'));


  if (user) {



    return {
      Accept: "application/json ,text/plain, */*",
      "Content-Type": "multipart/form-data",
      Authorization: 'Bearer ' + user.accessToken, 'Access-Control-Allow-Origin': '*',
    };

  } else {

    return {};
  }
}

