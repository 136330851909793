import { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";

import MDTypography from "common/components/MD/MDTypography";


// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";


import Alert from '@mui/material/Alert';
import FormControl from "@mui/material/FormControl";

import loading_image from 'common/assets/images/icons/loading-green-loading.gif';
// Data
import ExpoRichiesteService from "services/API/ExpoRichieste/ExpoRichiesteService";


import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { IconButton, MenuItem, Select } from "@mui/material";
import { ColumnFilter } from "common/components/implemented/Tables/DataTable/ColumnFilter";
import ExpoRichiesteTable from "./ExpoRichiesteTable";

import { NoneFilter } from "./NoneFilter";

import { SelectColumnFilterTipo } from "./SelectColumnFilterTipo";
import { useManifestazione } from "common/context/GlobalContext";


function ElencoRichiesteNR() {

  const [proposte, setProposte] = useState([]);


  const manifestazione = "Agriumbria 2025";
  //const { manifestazione} = useManifestazione();




  const [loading, setLoading] = useState(true);





  //per passarmi la variabiule se ho salvato e far comparire l'alert
  const location = useLocation();


  const { state } = location != null ? location : { state: { submitted: false, salvato: 'NO', indietro: false } }; // Read values passed on state




  useEffect(() => {

    console.log("FILTRO");
    setProposte([]);
    setLoading(true);

    ExpoRichiesteService.getAllRichieste(manifestazione, 'IN COMPILAZIONE', 'ALL')
      .then(response => {
        setProposte(response.data);
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        setLoading(false);
      });

  }, []);




  const ppAttributes = [];

  if (proposte.length > 0) {
    proposte.forEach(prop => {

      let action = (

        <MDBox><Link to="/expo_richiestaAccept" state={{ modo: 'edit', soggetto: prop, subject: prop.manifestazione, chiamante: "expo_richieste_nr_admin" }}>

          <MDButton variant="outlined" color={"success"} iconOnly={true}>
            <Icon>preview</Icon>
          </MDButton>
        </Link>

        </MDBox>
      );




  



      ppAttributes.push({
        er: prop,
        mqr: prop.mqr,
        protocollo: prop.protocollo,
        azienda: prop.ragione_sociale,
        data_invio: prop.string_data_agg,
        data_um: prop.string_data_agg,
        note: prop.note,
        cm_string: prop.cm_string,
        action: action,
        tipologia_espositiva_string: prop.tipologia_stand
      })
    });
  }



  const ppTableData = {
    rows: ppAttributes,

    columns: [

      { Header: "Protocollo", accessor: "protocollo", Filter: ColumnFilter, width: "4%", align: "left", id: "proposte_0" },

      { Header: "Azienda", accessor: "azienda", Filter: ColumnFilter, align: "left", with: "50%", id: "proposte_2" },
      { Header: "Codice Merceologico", accessor: "cm_string", Filter: ColumnFilter, width: "10%", align: "left", id: "proposte_3" },
      {
        Header: "Tipologia Espositiva Richiesta", accessor: "tipologia_espositiva_string", id: "proposte_4",

        Filter: SelectColumnFilterTipo,
        Cell: ({ row }) => {
          return (
            <MDBox>
              <MDBox> <MDTypography variant="button"
                fontWeight="regular"
                color="text">{row.original.tipologia_espositiva_string}</MDTypography></MDBox>
              {row.original.esigenze_espositive &&
                <MDBox>  <MDTypography variant="button"
                  fontWeight="regular"
                  color="text">*** {row.original.esigenze_espositive.substring(0, 20)}...</MDTypography> </MDBox>}
              {row.original.note &&
                <MDBox>  <MDTypography variant="button"
                  fontWeight="regular"
                  color="text">*** {row.original.note.substring(0, 20)}...</MDTypography> </MDBox>}
            </MDBox>

          );
        },
        width: "15%", align: "left"
      },
      { Header: "Mq richiesti", accessor: "mqr", Filter: NoneFilter, align: "left", width: "5%", id: "proposte_9" },

      { Header: "Data Ultima Modifica", accessor: "data_um", Filter: ColumnFilter, width: "10%", align: "left", id: "proposte_8" },
      { Header: "Azioni", accessor: "action", Filter: NoneFilter, align: "center", enableGlobalFilter: false, id: "proposte_1", disableSortBy: true },

    ],



  };

  const mypagination = { color: 'success', variant: "contained" };

  const panel_search = (
    <MDBox pt={4} pb={3} px={3} sx={{ display: 'grid', gap: 1, gridTemplateColumns: ' 1fr 1fr 1fr 1fr' }}  >

      <IconButton variant="outlined" color="success">
        <CleaningServicesIcon fontSize="small" /></IconButton>
    </MDBox>

  );



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderForm titolo="Proposte di Partecipazione In Compilazione" add="" modo="vis" />
              <MDBox pt={3}>
                <ExpoRichiesteTable canSearch={true}
                  table={ppTableData}
                  nome_tabella="proposte_nr"
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={mypagination}
                  noEndBorder
                  responsive={true}
                  first_load={state == null || !state.indietro}
                  filtro_server={true}
                  panel_search={panel_search}

                  canExport={true}
                  enableColumnFilters={true}


                />
              </MDBox>
              {loading &&
                <MDBox opacity={1} textAlign="center">
                  <img src={loading_image} alt="loading" width={100} />
                </MDBox>
              }

              {(proposte == null || proposte.length === 0) &&
                <MDBox style={{ height: "200px" }} textAlign='center'>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    Non ci sono Proposte di partecipazione in compilazione in archivio.
                  </MDTypography></MDBox>
              }
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}



export default ElencoRichiesteNR;
