import React, { useEffect, useState } from 'react';
//prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import MDBox from "common/components/MD/MDBox";
import MDInput from "common/components/MD/MDInput";
import { MenuItem, Select } from '@mui/material';
import UtilityService from 'services/API/utility/UtilityService';
export const SelectColumnFilterPostazione = ({ column }) => {

  const [lista_spazi, setLista_spazi] = useState([
    { codiceSpazio: '' },
  ]);

  useEffect(() => {
    //console.log("carico gli spazi");

    async function fetchInitialData2() {
      const response = await UtilityService.getAllSpazi();
      response.errors ? console.log(response.errors) : setLista_spazi([...response.data]);
    }
    fetchInitialData2();

  }, []);

  const { filterValue, setFilter } = column

  return (

    <MDBox ml="auto">

      <Select name="tipo_stand" id="tipo_stand"
        onChange={e => {
          console.log(e.target.value);
          sessionStorage.setItem("filter_" + column.id, e.target.value);
          if (e.target.value != 'ALL') {
            setFilter(e.target.value);
          }
          else {
            setFilter('');
          }
        }}
        value={lista_spazi.length>1 ?(filterValue || 'ALL'):'ALL'}
      > <MenuItem key="ALL" value="ALL">TUTTI</MenuItem>
        {lista_spazi.length>1 && lista_spazi.map((s) => (<MenuItem
          key={s.codiceSpazio}
          value={s.codiceSpazio} >
          {s.codiceSpazio}</MenuItem>
        ))}

      </Select>


    </MDBox>
  )

}

// Setting default values for the props of DataTable
SelectColumnFilterPostazione.propTypes = {

  filter: PropTypes.string,
  setFilter: PropTypes.func,
};

// Typechecking props for the DataTable
SelectColumnFilterPostazione.defaultProps = {
  filter: '',
  setFilter: null,
};