import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";
import MuiCheckbox from "@mui/material/Checkbox";

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";
import AnagDataTable from "common/components/implemented/Tables/DataTable/DataTables"
import Alert from '@mui/material/Alert';
//prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// Data
import AnagraficaDataService from "services/API/Anagrafica/AnagraficaService";
import UtilityService from "services/API/utility/UtilityService";


import base64ToArrayBuffer from "common/script/base64ToArrayBuffer";
import { FormControl, FormControlLabel, MenuItem, NativeSelect, Select } from "@mui/material";
import MDTypography from "common/components/MD/MDTypography";
import { Label } from "@mui/icons-material";
import AuthService from "services/API/Auth/AuthService";


function ElencoAnagrafiche({ subject }) {

  const [anagrafiche, setAnagrafiche] = useState([]);

  /*const [filtro_e, setFiltro_e] = useState(false);
  const [filtro_v, setFiltro_v] = useState(false);
  const [filtro_o, setFiltro_o] = useState(false);*/

  const [lista_professioni, setLista_professioni] = useState([
    { id: 0, professione: '' },
  ]);
  const [semaforo_p, setSemaforo_p] = useState(false);
  const [filtro_professione, setFiltro_professione] = useState('TUTTE');


  const [lista_codici_m, setLista_codici_m] = useState([
    { codice: '', descrizione: '' },
  ]);
  const [semaforo_cm, setSemaforo_cm] = useState(false);
  const [filtro_cm, setFiltro_cm] = useState('TUTTI');


  useEffect(() => {
    setLista_professioni([]);
      if (AuthService.isTokenOk()) {
    AnagraficaDataService.getAllProfessioni()
      .then(response => {
        return response.data;
      })
      .then(data => {
               const nextArtists = [
          // Elementi prima del punto di inserzione:
          ...data.slice(0, 0),
          // Nuovo elemento:
          { id: 0, professione: 'TUTTE' },
          // Elementi dopo il punto di inserzione:
          ...data.slice(0)
        ];
        setLista_professioni(nextArtists);
        console.log(nextArtists);
        setFiltro_professione('TUTTE');
        setSemaforo_p(true);

      }
      )
      .catch(e => {
        console.log(e);
        setSemaforo_p(true);
      });}
  }, []);

  useEffect(() => {
    setLista_codici_m([]);
     if (AuthService.isTokenOk()) {
    UtilityService.getAllCodiciM()
      .then(response => {
        return response.data;
      })
      .then(data => {

        const nextArtists = [
          // Elementi prima del punto di inserzione:
          ...data.slice(0, 0),
          // Nuovo elemento:
          { codice: 'TUTTI', descrizione: '' },
          // Elementi dopo il punto di inserzione:
          ...data.slice(0)
        ];
        setLista_codici_m(nextArtists);
        setFiltro_cm('TUTTI');
        setSemaforo_cm(true);
      }
      )
      .catch(e => {
        console.log(e);
        setSemaforo_cm(true);
      });}
  }, []);


  //per passarmi la variabiule se ho salvato e far comparire l'alert
  const location = useLocation();
  //console.log(location);

  const { state } = location != null ? location : { state: { submitted: false, salvato: 'NO', indietro: false } }; // Read values passed on state

  useEffect(() => {

    console.log("CARICO var di sessione");
    if (state == null || !state.indietro) resetState();
    else {
      //  console.log("STATE!=NULL ->"+filtro_man);
      /* if(sessionStorage.getItem("proposte_filtro_man")!='')
         setFiltro_man( sessionStorage.getItem("proposte_filtro_man"));*/

      /*  setFiltro_e( JSON.parse(sessionStorage.getItem("anag_filtro_e")));
        setFiltro_o( JSON.parse(sessionStorage.getItem("anag_filtro_o")));  
        setFiltro_v( JSON.parse(sessionStorage.getItem("anag_filtro_v")));*/
      //console.log("STATE!=NULL ->"+filtro_man);

    }

  }, []);

  const resetState = () => {
    console.log("RESETTO LA SESSIONE RICERCA")
    /* sessionStorage.removeItem('anag_filtro_e');
     setFiltro_e(true);
     sessionStorage.removeItem('anag_filtro_o');
     setFiltro_o(true);
     sessionStorage.removeItem('anag_filtro_v');
     setFiltro_v(true);*/
  };

  // Salva lo stato della pagina corrente in sessione ogni volta che cambia
  /*useEffect(() => {

    sessionStorage.setItem("anag_filtro_e", filtro_e);
  }, [filtro_e]);
  useEffect(() => {

    sessionStorage.setItem("anag_filtro_v", filtro_v);
  }, [filtro_v]);
  useEffect(() => {

    sessionStorage.setItem("anag_filtro_o", filtro_o);
  }, [filtro_o]);*/
  // Salva lo stato della pagina corrente in sessione ogni volta che cambia
  /* useEffect(() => {
    console.log("CAMBIO FILTRO STATO");
    sessionStorage.setItem("proposte_filtro_stato", JSON.stringify(filtro_stato));
  }, [filtro_stato]);*/

  // Salva lo stato della pagina corrente in sessione ogni volta che cambia
  /*  useEffect(() => {
     console.log("CAMBIO FILTRO STATO A="+filtro_stato_a);
     sessionStorage.setItem("proposte_filtro_stato_a", JSON.stringify(filtro_stato_a));
   }, [filtro_stato_a]);*/



  useEffect(() => {

    if (semaforo_cm && semaforo_p) {
        if (AuthService.isTokenOk()) {
      if (subject === 'O') {
        AnagraficaDataService.getAllOperatori(filtro_professione)
          .then(response => {
            setAnagrafiche(response.data);
           })
          .catch(e => {
            console.log(e);
          });
      } else {
        if (subject === 'V') {
       //   console.log(subject);
          AnagraficaDataService.getAllVisitatori(filtro_professione)
            .then(response => {
              console.log(response.data);
              setAnagrafiche(response.data);
            })
            .catch(e => {
              console.log(e);
            });
        } else {
          if (subject === 'E') {

          //  console.log(subject);
            AnagraficaDataService.getAllEspositori(filtro_cm)
              .then(response => {
                console.log(response.data);
                setAnagrafiche(response.data);
              })
              .catch(e => {
                console.log(e);
              });
          } else {


            console.log("FILTRO");

            AnagraficaDataService.getAllAnagrafiche(filtro_professione, filtro_cm)
              .then(response => {
                console.log(subject);
                setAnagrafiche(response.data);
                // console.log(response.data);

              })
              .catch(e => {
                console.log(e);
              });
          }
        }
      }
    }
    }

  }, [subject, filtro_professione, filtro_cm, semaforo_cm, semaforo_p]);






  const anagAttributes = [];
  if (anagrafiche.length > 0) {
    anagrafiche.forEach(anag => {

      let denominazione;
      if (anag.azienda !== '') {
        denominazione = anag.azienda;
        if (anag.cognome !== '') denominazione += "(" + anag.cognome + ' ' + anag.nome + ")"
      } else {
        denominazione = anag.cognome + ' ' + anag.nome;

      }
      let tipo = '';
      if (anag.visitatore) tipo += 'V';
      if (anag.operatore) tipo += ' O';
      if (anag.espositore) tipo += ' E';
      anagAttributes.push({
        tipo: tipo,
        denominazione: denominazione,
        email: anag.email,
        provenienza: anag.localita + '(' + anag.provincia + ')',
        professione: anag.espositore ? anag.cm_1_descrizione : anag.professione,
        action: (
          <Link to="/anagEdit" state={{ modo: 'edit', soggetto: anag, subject: subject }}
          >
            <MDButton variant="outlined" color={"success"} iconOnly={true}>
              <Icon>edit</Icon>
            </MDButton>
          </Link>
        )
      })
    });
  }
  const anagTableData = {
    columns: [
      { Header: "Tipo", accessor: "tipo", width: "5%", align: "left" },
      { Header: "Denominazione", accessor: "denominazione", width: "15%", align: "left" },
      { Header: "Email", accessor: "email", align: "left" },
      { Header: "Provenienza", accessor: "provenienza", align: "left" },
      { Header: "Professione", accessor: "professione", align: "left" },
      { Header: "action", accessor: "action", align: "center", enableGlobalFilter: false },
    ],

    rows: anagAttributes,


  };

  const mypagination = { color: 'success', variant: "contained" };

  async function esporta_xls() {
    console.log("esporta_xls");

    let filename = 'ListaAnagrafica.xls';

    AnagraficaDataService.esporta_xls(filtro_professione, filtro_cm)
      .then(response => {
        /*  const disposition = response.headers['content-disposition'];
         filename = disposition.split(/;(.+)/)[1].splitvv(/=(.+)/)[1];
          if (filename.toLowerCase().startsWith ("utf-8''"))
             filename = decodeURIComponent(filename.replace("utf-8''", ''));
          else
             filename = filename.replace(/['"]/g, '');*/
        return response.data;
      })
      .then(blob => {

        var arrrayBuffer = base64ToArrayBuffer(blob); //data is the base64 encoded string
        var blobb = new Blob([arrrayBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        var url = URL.createObjectURL(blobb);
        var a = document.createElement('a');
        a.href = url;
        a.target = "_blank";
        a.download = filename;
        document.body.appendChild(a); // append the element to the dom
        a.click();
        a.remove(); // afterwards, remove the element  
      })
      .catch(error => {
        console.error(error);
      });

  }


  const cambiatoProf = (e) => {
    //console.log('carico:');
    setFiltro_professione(e.target.value);

  }
  const cambiatoCm = (e) => {
    //console.log('carico:');
    setFiltro_cm(e.target.value);

  }
  const panel_search = (
    <MDBox pt={2} pb={2} px={3} >
      <MDBox sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr 1fr' }}>


      {(subject != 'E') &&
        <FormControl >
       
          <MDTypography variant="button" htmlFor="professione" shrink={true} >Professione</MDTypography>
          {(semaforo_p  && (subject != 'E') )  && <Select name='professione' id='professione'
            onChange={cambiatoProf}
            value={filtro_professione} disabled={!semaforo_p}
          >  {lista_professioni.map((prof) => (
            <MenuItem key={prof.professione}
              value={prof.professione}>
              {prof.professione}
            </MenuItem>
          ))}
          </Select>}
        </FormControl>}

{(subject != 'O' && subject != 'V') &&
        <FormControl >
          <MDTypography variant="button" htmlFor="cm" shrink={true} >Codice Merceologico</MDTypography>
          {(semaforo_cm && (subject != 'O' && subject != 'V'))  && <Select name='cm' id='cm'
            onChange={cambiatoCm}
            value={filtro_cm} disabled={!semaforo_cm}
          >  {lista_codici_m.map((c) => (
            <MenuItem key={c.codice}
              value={c.codice}>
              {c.codice == null ? "" : c.codice + " " + c.descrizione}
            </MenuItem>
          ))}
          </Select>}
        </FormControl>}
      </MDBox>

    </MDBox>

  );



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderForm titolo={subject === 'O' ? "Operatori" :
                subject === 'V' ? "Visitatori" :
                  subject === 'E' ? "Espositori" : "Tutte le anagrafiche"} add="/anagExists" modo="vis" subject={subject} />
              <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">

                {(state != null && state.submitted && (state.salvato === 'INS' || state.salvato === 'MOD')) &&

                  <Alert severity="success">Dati Anagrafici salvato con successo!</Alert>}
                {(state != null && state.submitted && state.salvato === 'DEL') &&

                  <Alert severity="success">Anagrafica eliminata con successo!</Alert>}
              </MDBox>
              <MDBox pt={3}>
                <AnagDataTable canSearch={true}
                  table={anagTableData}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={mypagination}
                  noEndBorder
                  nome_tabella={"anagrafica"+subject}
                  first_load={state == null || !state.indietro}
                  filtro_server={true}
                  panel_search={panel_search}
                  filtro_0={filtro_professione}
                  filtro_1={filtro_cm}

                  canExport={true} esporta_xls={esporta_xls}

                /*     state={pagination}
                   
                     onPaginationChange= {setPagination}*/

                />

              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

ElencoAnagrafiche.defaultProps = {
  subject: "ALL",

};

// Typechecking props for the Footer
ElencoAnagrafiche.propTypes = {
  subject: PropTypes.string,

};

export default ElencoAnagrafiche;
