import React from 'react';
//prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import MDBox from "common/components/MD/MDBox";
import MDInput from "common/components/MD/MDInput";
import { MenuItem, Select } from '@mui/material';
export const SelectColumnFilterMsg= ({column})=>{

  const{filterValue,setFilter}=column

    return (

        <MDBox ml="auto">

    <Select  name="msg"  id="msg" 
          onChange={e => {
            console.log(e.target.value);
            sessionStorage.setItem("filter_"+column.id,e.target.value);  
           if( e.target.value != 'ALL'){
            setFilter(e.target.value);}
           else {
           setFilter('');}
           }}
          value={filterValue || 'ALL'}
        >
          <MenuItem key="ALL" value="ALL">TUTTI</MenuItem>
          <MenuItem key="DA GENERARE" value="DA GENERARE">DA GENERARE</MenuItem>
          <MenuItem key="DA SCRIVERE" value="DA SCRIVERE">DA SCRIVERE</MenuItem>
          <MenuItem key="DA INVIARE" value="DA INVIARE">DA INVIARE</MenuItem>
          <MenuItem key="INVIATO" value="INVIATO">INVIATO</MenuItem>
         
          
        </Select>


      </MDBox>
    )

}

// Setting default values for the props of DataTable
SelectColumnFilterMsg.propTypes = {

    filter:PropTypes.string,
    setFilter: PropTypes.func,
  };
  
  // Typechecking props for the DataTable
  SelectColumnFilterMsg.defaultProps = {
    filter:'',
    setFilter: null,
  };